/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { HarvestDataItem, HarvestInfo } from "../helpers";

function HarvestReviewDiffSummary({ harvestData }: { harvestData: HarvestDataItem[] }) {
    const squareFootageMetrics = HarvestInfo.getSquareFootageMetrics(harvestData);

    return (
            <div className="diff-summary-usf-summary">
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label work">work</span>
                    <span className={
                        squareFootageMetrics.diff.work > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.work < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.work > 0 ? '+' + squareFootageMetrics.diff.work : squareFootageMetrics.diff.work}sf
                        {' (' + squareFootageMetrics.diffPercentage.work + '%)'}
                    </span>
                </div>
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label wash">wash</span>
                    <span className={
                        squareFootageMetrics.diff.wash > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.wash < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.wash > 0 ? '+' + squareFootageMetrics.diff.wash : squareFootageMetrics.diff.wash}sf
                        {' (' + squareFootageMetrics.diffPercentage.wash + '%)'}
                    </span>
                </div>
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label meet">meet</span>
                    <span className={
                        squareFootageMetrics.diff.meet > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.meet < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.meet > 0 ? '+' + squareFootageMetrics.diff.meet : squareFootageMetrics.diff.meet}sf
                        {' (' + squareFootageMetrics.diffPercentage.meet + '%)'}
                    </span>
                </div>
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label circulate">circulate</span>
                    <span className={
                        squareFootageMetrics.diff.circulate > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.circulate < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.circulate > 0 ? '+' + squareFootageMetrics.diff.circulate : squareFootageMetrics.diff.circulate}sf
                        {' (' + squareFootageMetrics.diffPercentage.circulate + '%)'}
                    </span>
                </div>
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label we">we</span>
                    <span className={
                        squareFootageMetrics.diff.we > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.we < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.we > 0 ? '+' + squareFootageMetrics.diff.we : squareFootageMetrics.diff.we}sf
                        {' (' + squareFootageMetrics.diffPercentage.we + '%)'}
                    </span>
                </div>
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label operate">operate</span>
                    <span className={
                        squareFootageMetrics.diff.operate > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.operate < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.operate > 0 ? '+' + squareFootageMetrics.diff.operate : squareFootageMetrics.diff.operate}sf
                        {' (' + squareFootageMetrics.diffPercentage.operate + '%)'}
                    </span>
                </div>
                <div className="diff-summary-usf-summary-item">
                    <span className="diff-summary-usf-summary-item-label other">other</span>
                    <span className={
                        squareFootageMetrics.diff.other > 0
                            ? "diff-summary-usf-summary-item-value more"
                            : squareFootageMetrics.diff.other < 0
                                ? "diff-summary-usf-summary-item-value less"
                                : "diff-summary-usf-summary-item-value"
                    }>
                        {squareFootageMetrics.diff.other > 0 ? '+' + squareFootageMetrics.diff.other : squareFootageMetrics.diff.other}sf
                        {' (' + squareFootageMetrics.diffPercentage.other + '%)'}
                    </span>
                </div>
            </div>
    );
}

export default React.memo(HarvestReviewDiffSummary);
