/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../helpers/helpers";
import { VIEW_TYPE } from "../HarvestReview";
import { applyFilterMetric, ChairsList, HarvestDataItem, HarvestInfo } from '../helpers';
import { FilterMetric } from "./HarvestReviewDiffSummaryMetrics";

const arrowIcon = require('../../../assets/images/arrow.png');
const arrowExtendIcon = require('../../../assets/images/arrow-extend.png');
const shareIcon = require('../../../assets/images/share.svg');
const starIcon = require('../../../assets/images/star.svg');

function HarvestReviewTable({ viewType, chairsList, harvestData, selectedId, filterMetric, onClickRow }: {
    viewType: VIEW_TYPE,
    chairsList: ChairsList,
    harvestData: HarvestDataItem[],
    selectedId: string | undefined,
    filterMetric: FilterMetric,
    onClickRow: (uuid: string, centroidInFeet: { x: number, y: number } | undefined) => void,
}) {
    var viewedRoomsFromStorage = JSON.parse(localStorage.getItem("viewedRooms") as string);
    const [viewedRooms, setViewedRooms] = useState<string[]>(viewedRoomsFromStorage ? viewedRoomsFromStorage : []);

    const copy = () => {
        navigator.clipboard.writeText(window.location.href);
    }

    const onViewedRoom = (uuid: string) => {
        let newViewedRooms: string[] = []
        if(viewedRooms.includes(uuid)) {
            newViewedRooms = viewedRooms.filter((id: string) => id !== uuid) 
        } else {
            newViewedRooms = [...viewedRooms, uuid]
        }
        localStorage.setItem("viewedRooms", JSON.stringify(newViewedRooms));
        setViewedRooms(newViewedRooms)
    }

    const dataRooms = harvestData
        ?.filter((harvestDataRow) => (
            (harvestDataRow.previous?.uuid || harvestDataRow.current?.uuid) && applyFilterMetric(filterMetric, harvestDataRow) 
        ))
        ?.sort((a: HarvestDataItem, b: HarvestDataItem) => {
            const nameA = (a.current?.roomNumber || a.previous?.roomNumber)?.replace('-', '').toLowerCase() || ''
            const nameB = (b.current?.roomNumber || b.previous?.roomNumber)?.replace('-', '').toLowerCase() || ''
            return nameA > nameB ? 1 : -1
        })

    const dataChairs = chairsList.previousChairsWithInventory
        ?.map((item) => {
            const currentMatches = chairsList.allCurrentChairs?.filter(current => current.uuid === item.uuid) || []
            return {
                uuid: item.uuid,
                inventoryName: item.inventoryName,
                centroidInFeet: item.centroidInFeet,
                previousInventory: !!item.inventoryId,
                currentInventory: currentMatches.length > 0,
                unlinked: item.inventoryId && currentMatches.length === 0
            }
        })
        ?.filter((item) => {
            if(filterMetric === FilterMetric.UNLINKED_CHAIR_INV) {
                return item.unlinked
            } else {
                return true
            }
        })

    return (
        <>
            <div className="harvest-review-list-header">
                <span className="ray-tag">
                    { filterMetric }
                </span>
            </div>
            <div className="harvest-review-list"
                id="harvest-review-list"
            >
                {
                    (
                        viewType === VIEW_TYPE.ROOM 
                        || viewType === VIEW_TYPE.ALL
                    ) && (
                        filterMetric !== FilterMetric.UNLINKED_CHAIR_INV   
                    ) && dataRooms
                        ?.map((harvestDataRow: HarvestDataItem) => (
                            <div className={
                                selectedId === (harvestDataRow.previous?.uuid || harvestDataRow.current?.uuid)
                                    ? "harvest-review-list-item highlighted-room"
                                    : "harvest-review-list-item"
                            }
                                key={
                                    harvestDataRow.previous?.uuid || harvestDataRow.current!.uuid
                                }
                                id={
                                    harvestDataRow.previous?.uuid || harvestDataRow.current!.uuid
                                }
                            >
                                <div className="harvest-review-list-item-top">
                                    <div 
                                        className="harvest-review-list-item-room-number"
                                        onClick={() => onClickRow(
                                            harvestDataRow.current?.uuid || harvestDataRow.previous?.uuid || '',
                                            harvestDataRow.current?.centroidInFeet || harvestDataRow.previous?.centroidInFeet
                                        )}
                                    >
                                        <span>
                                            <img src={arrowExtendIcon.default} alt="" 
                                                className={
                                                    selectedId === (harvestDataRow.previous?.uuid || harvestDataRow.current?.uuid)
                                                        ? "inventory-summary-item-icon rotate-90-deg" 
                                                        : "inventory-summary-item-icon"
                                                }

                                            />
                                        </span>
                                        {
                                            `Room ${harvestDataRow.current?.roomNumber || harvestDataRow.previous?.roomNumber}`
                                        }
                                    </div> 
                                    <div>
                                        <span
                                            className={
                                                selectedId === (harvestDataRow.previous?.uuid || harvestDataRow.current!.uuid)
                                                    ? "harvest-review-list-header-icon"
                                                    : "display-none"
                                            }
                                            onClick={copy}
                                        >
                                            <img src={shareIcon.default} alt="" className="inventory-summary-item-icon" />
                                        </span>
                                        <span
                                            className="harvest-review-list-header-icon"
                                            onClick={() => onViewedRoom((harvestDataRow.current?.uuid || harvestDataRow.previous!.uuid) as string)}
                                        >
                                            <img src={starIcon.default} alt="" 
                                                className={
                                                    viewedRooms.includes((harvestDataRow.current?.uuid || harvestDataRow.previous?.uuid) as string)
                                                    || (selectedId === (harvestDataRow.previous?.uuid || harvestDataRow.current!.uuid))
                                                        ? (
                                                            viewedRooms.includes((harvestDataRow.current?.uuid || harvestDataRow.previous?.uuid) as string)
                                                                ? "inventory-summary-item-icon selected-room" 
                                                                : "inventory-summary-item-icon" 
                                                        )
                                                        : "display-none"
                                                }
                                            />
                                        </span>
                                    </div>
                                </div> 
                                <div
                                    onClick={() => onClickRow(
                                        harvestDataRow.current?.uuid || harvestDataRow.previous?.uuid || '',
                                        harvestDataRow.current?.centroidInFeet || harvestDataRow.previous?.centroidInFeet
                                    )}
                                >
                                    <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> OFFICE #</div>
                                            <div className={
                                                (harvestDataRow.previous?.roomNumber !== harvestDataRow.current?.roomNumber) 
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                                {
                                                    harvestDataRow.previous?.roomNumber
                                                }
                                            </div> 
                                            {
                                                (harvestDataRow.previous?.roomNumber !== harvestDataRow.current?.roomNumber) 
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" 
                                                            className={ "inventory-summary-item-icon" 
                                                        }/>
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            }
                                            <div className="harvest-review-list-item-item-current">
                                                {
                                                    harvestDataRow.current?.roomNumber
                                                }
                                            </div> 
                                    </div>  
                                    <div className="harvest-review-list-item-item">
                                            <span className="harvest-review-list-item-item-label"> Space Type</span>
                                            <span className={
                                                (
                                                    (harvestDataRow.previous?.spaceType || harvestDataRow.previous?.designedAsSpaceType) 
                                                    !== (harvestDataRow.current?.spaceType || harvestDataRow.current?.designedAsSpaceType)
                                                ) 
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                            {
                                                harvestDataRow.previous?.spaceType || harvestDataRow.previous?.designedAsSpaceType
                                                    ? capitalizeFirstLetter(harvestDataRow.previous.spaceType || harvestDataRow.previous.designedAsSpaceType)
                                                    : '-'
                                            }
                                            </span>
                                            {
                                                (
                                                    (harvestDataRow.previous?.spaceType || harvestDataRow.previous?.designedAsSpaceType) 
                                                    !== (harvestDataRow.current?.spaceType || harvestDataRow.current?.designedAsSpaceType)
                                                ) 
                                                ? (
                                                    <span className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </span>
                                                )
                                                : <span className="harvest-review-list-item-item-change-icon"></span>
                                            }
                                            <span className="harvest-review-list-item-item-current">
                                            {
                                                harvestDataRow.current?.spaceType || harvestDataRow.current?.designedAsSpaceType
                                                    ? capitalizeFirstLetter(harvestDataRow.current.spaceType || harvestDataRow.current.designedAsSpaceType)
                                                    : '-'
                                            }
                                            </span> 
                                    </div>    
                                    <div className="harvest-review-list-item-extended">  
                                        <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> PRGRM type</div>
                                            <div className={
                                                (
                                                    (harvestDataRow.previous?.programType || harvestDataRow.previous?.designedAsProgramType) 
                                                    !== (harvestDataRow.current?.programType || harvestDataRow.current?.designedAsProgramType)
                                                )
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                                {
                                                    harvestDataRow.previous?.programType || harvestDataRow.previous?.designedAsProgramType
                                                        ? capitalizeFirstLetter(harvestDataRow.previous.programType || harvestDataRow.previous.designedAsProgramType)
                                                        : '-'
                                                }
                                            </div> 
                                            {
                                                (
                                                    (harvestDataRow.previous?.programType || harvestDataRow.previous?.designedAsProgramType) 
                                                    !== (harvestDataRow.current?.programType || harvestDataRow.current?.designedAsProgramType)
                                                )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            }
                                            <div className="harvest-review-list-item-item-current">
                                            {
                                                harvestDataRow.current?.programType || harvestDataRow.current?.designedAsProgramType
                                                    ? capitalizeFirstLetter(harvestDataRow.current.programType || harvestDataRow.current.designedAsProgramType)
                                                    : '-'
                                            }
                                            </div> 
                                        </div>      
                                        <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> SQFT </div>
                                            <div className={
                                                (
                                                    (harvestDataRow.previous?.metrics?.squareFootage || harvestDataRow.current?.metrics?.squareFootage)
                                                    && (harvestDataRow.previous?.metrics?.squareFootage !== harvestDataRow.current?.metrics?.squareFootage)
                                                )
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                            {
                                                harvestDataRow.previous?.metrics?.squareFootage
                                                    ? Number(harvestDataRow.previous.metrics?.squareFootage).toFixed(2) + ' sq. ft'
                                                    : 0
                                            }
                                            </div> 
                                            {
                                                (
                                                    (harvestDataRow.previous?.metrics?.squareFootage || harvestDataRow.current?.metrics?.squareFootage)
                                                    && (harvestDataRow.previous?.metrics?.squareFootage !== harvestDataRow.current?.metrics?.squareFootage)
                                                )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            }
                                            <div className="harvest-review-list-item-item-current">
                                                <span className="harvest-review-list-item-item-current-val">
                                                {
                                                    harvestDataRow.current?.metrics?.squareFootage
                                                        ? Number(harvestDataRow.current.metrics?.squareFootage).toFixed(2) + ' sqft'
                                                        : 0
                                                }
                                                </span> 
                                                <br/>
                                                {
                                                    harvestDataRow.current?.metrics?.squareFootage && (
                                                        <span className={
                                                            HarvestInfo.getDiff(harvestDataRow.previous?.metrics?.squareFootage || 0, harvestDataRow.current?.metrics?.squareFootage || 0) > 0
                                                            ? "harvest-review-list-item-item-current-diff grean"
                                                            : HarvestInfo.getDiff(harvestDataRow.previous?.metrics?.squareFootage || 0, harvestDataRow.current?.metrics?.squareFootage || 0) < 0
                                                                ? "harvest-review-list-item-item-current-diff red"
                                                                : "harvest-review-list-item-item-current-diff"
                                                        }>
                                                        {
                                                            HarvestInfo.getDiff(harvestDataRow.previous?.metrics?.squareFootage || 0, harvestDataRow.current?.metrics?.squareFootage || 0) + ' sq.ft'
                                                        }
                                                        {
                                                            ` (${HarvestInfo.getPercentageChange(
                                                                harvestDataRow.previous?.metrics?.squareFootage || 0, 
                                                                harvestDataRow.current?.metrics?.squareFootage || 0
                                                            )}%)`
                                                        }
                                                        </span> 
                                                    )
                                                }
                                            </div>
                                        </div>      
                                        <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> WORK UNITS </div>
                                            <div className={
                                                (
                                                    (harvestDataRow.previous?.metrics?.workUnits || harvestDataRow.current?.metrics?.workUnits)
                                                    && (harvestDataRow.previous?.metrics?.workUnits !== harvestDataRow.current?.metrics?.workUnits)
                                                )
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                            {
                                                harvestDataRow.previous?.metrics?.workUnits
                                                    ? harvestDataRow.previous.metrics?.workUnits
                                                    : 0
                                            }
                                            </div> 
                                            {
                                                (
                                                    (harvestDataRow.previous?.metrics?.workUnits || harvestDataRow.current?.metrics?.workUnits)
                                                    && (harvestDataRow.previous?.metrics?.workUnits !== harvestDataRow.current?.metrics?.workUnits)
                                                )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            }
                                            <div className="harvest-review-list-item-item-current">
                                                <span className="harvest-review-list-item-item-current-val">
                                                {
                                                    harvestDataRow.current?.metrics?.workUnits
                                                        ? harvestDataRow.current.metrics?.workUnits
                                                        : 0
                                                }
                                                </span>
                                                <br/>
                                                {
                                                    (harvestDataRow.previous?.metrics?.workUnits || harvestDataRow.current?.metrics?.workUnits) && (
                                                        <span className={
                                                            HarvestInfo.getDiff(harvestDataRow.previous?.metrics?.workUnits || 0, harvestDataRow.current?.metrics?.workUnits || 0) > 0
                                                            ? "harvest-review-list-item-item-current-diff grean"
                                                            : HarvestInfo.getDiff(harvestDataRow.previous?.metrics?.workUnits || 0, harvestDataRow.current?.metrics?.workUnits || 0) < 0
                                                                ? "harvest-review-list-item-item-current-diff red"
                                                                : "harvest-review-list-item-item-current-diff"
                                                        }>
                                                        {
                                                            HarvestInfo.getDiff(harvestDataRow.previous?.metrics?.workUnits || 0, harvestDataRow.current?.metrics?.workUnits || 0)
                                                        }
                                                        {
                                                            ` (${HarvestInfo.getPercentageChange(
                                                                harvestDataRow.previous?.metrics?.workUnits || 0, 
                                                                harvestDataRow.current?.metrics?.workUnits || 0
                                                            )}%)`
                                                        }
                                                        </span> 
                                                    )
                                                }
                                            </div> 
                                        </div>      
                                        <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> INT. ROOMS </div>
                                            <div className={
                                                (
                                                    (harvestDataRow.previous?.metrics?.internalRoomCount || harvestDataRow.current?.metrics?.internalRoomCount)
                                                    && (harvestDataRow.previous?.metrics?.internalRoomCount !== harvestDataRow.current?.metrics?.internalRoomCount)
                                                )
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                            {
                                                harvestDataRow.previous?.metrics?.internalRoomCount
                                                    ? harvestDataRow.previous.metrics?.internalRoomCount
                                                    : 0
                                            }
                                            </div>
                                            {
                                                (
                                                    (harvestDataRow.previous?.metrics?.internalRoomCount || harvestDataRow.current?.metrics?.internalRoomCount)
                                                    && (harvestDataRow.previous?.metrics?.internalRoomCount !== harvestDataRow.current?.metrics?.internalRoomCount)
                                                )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            } 
                                            <div className="harvest-review-list-item-item-current">
                                            {
                                                harvestDataRow.current?.metrics?.internalRoomCount
                                                    ? harvestDataRow.current.metrics?.internalRoomCount
                                                    : 0
                                            }
                                            </div> 
                                        </div>      
                                        <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> WINDOW </div>
                                            <div className={
                                                harvestDataRow.previous?.hasWindow !== harvestDataRow.current?.hasWindow
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                            {
                                                harvestDataRow.previous?.hasWindow
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            </div> 
                                            {
                                                (
                                                    harvestDataRow.previous?.hasWindow !== harvestDataRow.current?.hasWindow
                                                )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            }
                                            <div className="harvest-review-list-item-item-current">
                                            {
                                                harvestDataRow.current?.hasWindow
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            </div> 
                                        </div>      
                                        <div className="harvest-review-list-item-item">
                                            <div className="harvest-review-list-item-item-label"> INVENTORY </div>
                                            <div className={
                                                harvestDataRow.previous?.inventoryId !== harvestDataRow.current?.inventoryId
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                            }>
                                            {
                                                harvestDataRow.previous?.inventoryId
                                                    ? 'Linked'
                                                    : 'Not linked'
                                            }
                                            </div> 
                                            {
                                                (
                                                    harvestDataRow.previous?.inventoryId !== harvestDataRow.current?.inventoryId
                                                )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                            }
                                            <div className="harvest-review-list-item-item-current">
                                            {
                                                harvestDataRow.current?.inventoryId
                                                    ? 'Linked'
                                                    : 'Not linked'
                                            }
                                            </div> 
                                        </div> 
                                    </div>  
                                </div>                                        
                            </div>
                        ))
                }
                {
                    (
                        viewType === VIEW_TYPE.CHAIR 
                        || viewType === VIEW_TYPE.ALL
                    ) && (
                        filterMetric === FilterMetric.TOTAL_CHANGES
                        || filterMetric === FilterMetric.UNLINKED_CHAIR_INV
                    ) && dataChairs
                        ?.map((item) => (
                            <div className={
                                selectedId === item.uuid
                                    ? "harvest-review-list-item highlighted-room"
                                    : "harvest-review-list-item"
                            }
                                key={
                                    item.uuid
                                }
                                id={
                                    item.uuid
                                }
                                onClick={() => onClickRow(
                                    item.uuid,
                                    item.centroidInFeet
                                )}
                            >
                                <div className="harvest-review-list-item-top">
                                    <div 
                                        className="harvest-review-list-item-room-number"
                                    >
                                        {
                                            `Chair ${item.inventoryName}`
                                        }
                                    </div>
                                </div> 
                                <div className="harvest-review-list-item-extended">  
                                    <div className="harvest-review-list-item-item">
                                        <div className="harvest-review-list-item-item-label"> INVENTORY </div>
                                        <div className={
                                                item.previousInventory !== item.currentInventory
                                                    ? "harvest-review-list-item-item-previous line-through"
                                                    : "harvest-review-list-item-item-previous previous-the-same"
                                        }>
                                            {
                                                item.previousInventory
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                        </div> 
                                        {
                                            (
                                                item.previousInventory !== item.currentInventory
                                            )
                                                ? (
                                                    <div className="harvest-review-list-item-item-change-icon">
                                                        <img src={arrowIcon.default} alt="" className="inventory-summary-item-icon" />
                                                    </div>
                                                )
                                                : <div className="harvest-review-list-item-item-change-icon"></div>
                                        }
                                        <div className="harvest-review-list-item-extended">
                                            <div className="harvest-review-list-item-item-current">
                                            {
                                                    item.currentInventory
                                                        ? 'Yes'
                                                        : 'No'
                                            }
                                            </div> 
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        ))
                }
            </div>
        </>
    );
}

export default React.memo(HarvestReviewTable);
