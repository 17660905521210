import React from 'react';
import { ERROR_SLACK_CHANNEL } from '../../constants';
import './FloorErrorLoading.css';

const emptyIcon = require('../../assets/images/48.png');

type FloorEloorErrorLoadingType = {
    show: boolean,
    retryTimes: number
}

const FloorErrorLoading = React.memo(function ({
    show,
    retryTimes
}: FloorEloorErrorLoadingType) {
    if (!show) return <></>;
    return (
        <>
            <div className={show ? "floor-empty" : "display-none"}>
                <span>
                    <img src={emptyIcon.default} alt="" className="inventory-summary-item-icon" />
                </span>
                <p className="floor-empty-row floor-empty-title">
                    Error loading map
                </p>
                <p className="floor-empty-row text-small">
                    Whoops! We're having some network issues.
                </p>
                <p className="text-small">
                    Try again or report the issue.
                </p>
                <div className="floor-empty-actions">

                    <input
                        className="floor-empty-row floor-plan-btn secondary-with-border info-section-btn text-small floor-empty-action-retry"
                        type="button"
                        value="Retry"
                        onClick={() => {
                            window.location.reload();
                        }}
                    />
                    <a className={retryTimes >= 2 ? "" : "display-none"}
                        href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                        <input
                            className="floor-empty-row floor-plan-btn info-section-btn text-small"
                            type="button"
                            value="Report"
                        />
                    </a>
                </div>
            </div>
        </>
    );
})

export default FloorErrorLoading;