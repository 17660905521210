import moment from 'moment';

export const getFormattedDate = (d: string | null, format: string, text = '') => {
    return d
        ? text + moment(d).format(format)
        : ''
}

export const getFormattedText = (text: string) => {
    return text?.charAt(0).toUpperCase()
        + text?.slice(1).toLowerCase()
}

export const copyToClipboard = (text: string) => {
    window.focus();
    navigator?.clipboard?.writeText(text)
};

export const getFromLocalStorage = (name: string, defaultValue: any) => {
    let result = defaultValue;
    try {
        result = localStorage.getItem(name)
            ? JSON.parse(localStorage.getItem(name) as any)
            : defaultValue
    } finally {
        return result;
    }
}

export const setToLocalStorage = (name: string, value: any, stringify = true) => {
    let result = value;
    try {
        result = stringify ? JSON.stringify(value) : value;
        localStorage.setItem(name, result)
    } finally {
        return result;
    }
}

export  function capitalizeFirstLetter(s: string) {
    return s
        ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
        : s;
  }