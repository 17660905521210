/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Dispatch} from "redux"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import {withRouter} from 'react-router-dom'
import {
    Annotation,
    FloorChildren,
    FloorPlanBaseMapStyle,
    FloorPlanConfig,
    FloorPlanData,
    FloorPlanDataService,
    FloorPlanEvent,
    FloorPlanLoadEvent,
    FPObject,
    FPRoom,
    PolygonAnnotation,
    PolygonAnnotationStyle,
    ReactFloorPlan,
} from "@wework/react-floor-plan-sdk";
import lodash from "lodash";
import Select from 'react-select'
import InfoSection from '../../components/InfoSection/InfoSection';
import Input from "../../components/Input/Input";
import {
    CHAIR_STYLES,
    customStyles,
    ERROR_SLACK_CHANNEL,
    FLOOR_PLAN_ENVIRONMENT,
    ROOM_STYLES,
    TABLE_STYLES
} from "../../constants";
import {useAuth0} from "@auth0/auth0-react";
import {
    cleanBuildingResult,
    cleanFloor,
    fetchBuildingResult,
    loadBuildingState,
    setBuildingId,
    setError,
    setFloor,
    setLoading,
    setMappedToRevitFromLocalStorage,
    setSelectedFeature
} from "../../store/actions";
import {Floor, MappedToRevit, SelectedFeature, STATE, VisualValidationBuildingState} from "../../store/types";
import Loader from "../../components/Loader/Loader";

import {RoomScheduleExport} from "../../helpers/RoomScheduleExport";
import FloorErrorEmpty from "../../components/floorErrorEmpty/FloorErrorEmpty";
import FloorErrorLoading from "../../components/floorErrorLoading/FloorErrorLoading";

import './Building.css';
import {getObjectName} from "./helpers";

const doorIcon = require('../../assets/images/door.svg');
const chairIcon = require('../../assets/images/chair.svg');

const floorPlanChildren: FloorChildren[] = [FloorChildren.FLOOR, FloorChildren.OBJECTS, FloorChildren.ROOMS, FloorChildren.WALLS, FloorChildren.DOORS]

function Building({ history, match }: any) {
    const [searchText, setSearchText] = useState<string>('');
    const [annotations, setAnnotations] = useState<Annotation[]>([]);
    const [objects, setObjects] = useState<FPObject[]>([]);
    const [rooms, setRooms] = useState<FPRoom[]>([]);
    const [zoomToFeatureId, setZoomToFeatureId] = useState<string | null>(null)
    const [chairsWithInventory, setChairsWithInventory] = useState<FPObject[]>([]);
    const [showChairs, setShowChairs] = useState(false)
    const [isFloorEmpty, setIsFloorEmpty] = useState(false)
    const [isErrorLoading, setIsErrorLoading] = useState(false)
    const [retryTimes, setRetryTimes] = useState(+(localStorage.getItem('retry') || 0))
    const [, setFloorChairsLoaded] = useState(false)
    const [showFurniture, setShowFurniture] = useState(false)
    const relationships = useRef(new Map());

    const { logout, user, getAccessTokenSilently } = useAuth0();

    const dispatch: Dispatch<any> = useDispatch();
    const _setMappedToRevitFromLocalStorage = React.useCallback(() => dispatch(setMappedToRevitFromLocalStorage()), [dispatch]);
    const _setSelectedFeature = React.useCallback((data: SelectedFeature) => dispatch(setSelectedFeature(data)), [dispatch]);
    const _getBuildingResult = React.useCallback((id, token: string) => dispatch(fetchBuildingResult(id, token)), [dispatch]);
    const _setFloor = React.useCallback((data: Floor) => dispatch(setFloor(data)), [dispatch]);
    const _setBuildingId = React.useCallback((value: string) => dispatch(setBuildingId(value)), [dispatch])
    const _cleanFloor = React.useCallback(() => dispatch(cleanFloor()), [dispatch]);
    const _cleanBuildingResult = React.useCallback(() => dispatch(cleanBuildingResult()), [dispatch]);
    const _setLoading = React.useCallback((state: boolean) => dispatch(setLoading(state)), [dispatch])
    const _setDownloadError = React.useCallback(() => dispatch(setError({ type: 'download' })), [dispatch])
    const _loadBuildingState = React.useCallback((buildingId: string, token: string) => dispatch(loadBuildingState(buildingId, token)), [dispatch]);
    const _cleanError = React.useCallback(() => dispatch(setError(null)), [dispatch])

    const buildingState: VisualValidationBuildingState = useSelector((state: STATE) => state.buildingState, shallowEqual);
    const building = useSelector((state: STATE) => state.buildingResult, shallowEqual);
    const mappedToRevit: MappedToRevit = useSelector((state: STATE) => state.mappedToRevit, shallowEqual);
    const selectedFeature: SelectedFeature = useSelector((state: STATE) => state.selectedFeature, shallowEqual);
    const floor = useSelector((state: STATE) => state.floor, shallowEqual);
    const buildingId = useSelector((state: STATE) => state.buildingId, shallowEqual);
    const loading = useSelector((state: STATE) => state.loading, shallowEqual);
    const error = useSelector((state: STATE) => state.error, shallowEqual);

    const floorPlanService = new FloorPlanDataService(FLOOR_PLAN_ENVIRONMENT, getAccessTokenSilently)
    const scheduleExportService = new RoomScheduleExport(floorPlanService)

    const convertFPObjectsToPolygonAnnotation = (objects: FPObject[]): PolygonAnnotation[] => {
        return objects.map((object) => {
            const id = object.uuid as string;
            const type = object.objectType
            let newStyle: PolygonAnnotationStyle
            if (type === 'CHAIR') {
                if (object.inventoryId) {
                    newStyle = mappedToRevit?.chairs?.includes(id) ? CHAIR_STYLES.MAPPED : CHAIR_STYLES.NOT_MAPPED
                } else {
                    newStyle = CHAIR_STYLES.WITHOUT_INVENTORY
                }
            } else {
                newStyle = object.inventoryId ? TABLE_STYLES : CHAIR_STYLES.WITHOUT_INVENTORY
            }

            const annotation: PolygonAnnotation = {
                id,
                type: 'Feature' as const,
                geometry: {
                    type: 'Polygon' as const,
                    coordinates: [object.projectedBoundariesInFeet?.outerBoundary?.pointsList.map(p => [p.x, p.y]) || []]
                        .concat(
                            object.projectedBoundariesInFeet?.innerVoidBoundariesList?.map((boundary) =>
                                boundary.pointsList.map(p => [p.x, p.y]) || []
                            ) || []
                        ),
                },
                properties: {
                    type,
                    inventoryId: object.inventoryId,
                    deskNumber: getObjectName(object),
                    label: '',
                    ...newStyle
                }
            }

            if (object.objectType === "CHAIR" && object.inventoryId && getObjectName(object)) {
                annotation.properties.label = getObjectName(object)
            }

            return annotation
        })
            .sort((a) => {
                if (a.properties.type === 'CHAIR') return 1
                return -1
            })
    }

    const clean = () => {
        setAnnotations([]);
        setObjects([]);
        setChairsWithInventory([]);
        relationships.current.clear();
        _setSelectedFeature({ table: null, chair: null, room: null });
        _setBuildingId('');
        _cleanBuildingResult();
        _cleanFloor();
        _cleanError()
    }

    useEffect(() => {
        _setBuildingId(match?.params?.building)

        return clean;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (error?.type === 'download') {
        } else if (error) {
            const retry = +(localStorage.getItem('retry') || 0) + 1
            localStorage.setItem('retry', '' + retry)
            setRetryTimes(retry)
            setIsErrorLoading(true)
            _cleanError()
        } else {
            _cleanError()
        }
    }, [error])

    // Observe buildingId in URL and fetch building and building status
    useEffect(() => {
        if (!buildingId) return;
        getAccessTokenSilently().then((token) => {
            _loadBuildingState(buildingId, token)
            if (building?.id !== buildingId) _getBuildingResult(buildingId, token)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingId])

    useEffect(() => {
        if (!building || !building?.floors?.length) return;

        const _floor = building?.floors.filter((floor) => floor.id === match?.params?.floor)[0] || building?.floors[0];

        _setFloor(_floor)
        setFloorChairsLoaded(false)
        history.push(
            '/building/' + building.id + '/' + _floor.id
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [building, setFloorChairsLoaded])

    useEffect(lodash.debounce(() => { // eslint-disable-line react-hooks/exhaustive-deps
        if (!objects.length) return

        relationships.current.clear();
        objects.forEach((object) => {
            if (object.objectType === 'CHAIR' && object.parentObjectUuid) {
                relationships.current.set(object.parentObjectUuid, object.uuid);
                relationships.current.set(object.uuid, object.parentObjectUuid);
            }
        });

        let newAnnotations = convertFPObjectsToPolygonAnnotation(objects)
        setAnnotations && setAnnotations(newAnnotations)
        if (chairsWithInventory.length === 0) setShowChairs(false)

    }, 200), [objects, chairsWithInventory.length])

    const customRoomStyle = {
        onRoom: (room: FPRoom): PolygonAnnotationStyle | null => {
            if (showChairs) return ROOM_STYLES.CHAIR_MODE
            if (mappedToRevit?.rooms?.includes(room.uuid)) return ROOM_STYLES.MAPPED
            return null
        }
    }

    const mapClick = (e: FloorPlanEvent) => {
        let _chair: Annotation | undefined

        e.features.annotations.forEach(_annotation => {
            if (_annotation?.properties.type === "CHAIR" && _annotation.properties.inventoryId) {
                _chair = _annotation
            }
        });
        const _room = showChairs ? undefined : e.features.rooms?.[0]

        const isChairSelected = _chair?.id
            && _chair?.id === selectedFeature.chair?.uuid
            && _chair?.properties.type === "CHAIR";
        const isRoomSelected = !_chair?.id && !selectedFeature.chair?.uuid
            && _room?.uuid && _room?.uuid === selectedFeature.room?.room.uuid;

        if (isChairSelected || isRoomSelected) {
            _setSelectedFeature({ table: null, chair: null, room: null })
        } else {
            _setSelectedFeature({
                table: null,
                chair: _chair ? chairsWithInventory.find((c) => c.uuid === _chair!.id) || null : null,
                room: _room ? { room: _room, rooms: rooms } : null
            })
        }
    }

    function downloadRevitRoomScheduleXLSX() {
        _setLoading({ type: 'download' } as any)
        if (building) scheduleExportService.loadAndPromptDownload(building)
            .catch((e) => {
                _setDownloadError()
                console.log("Export failed: " + String(e))
            })
            .finally(() => {
                _setLoading(false)
            })
    }

    const config: FloorPlanConfig = {
        environment: FLOOR_PLAN_ENVIRONMENT,
        auth0TokenProvider: getAccessTokenSilently,
        includeInventoryData: true
    }

    function toggleShowChairs(value: boolean) {
        _setSelectedFeature({ room: null, chair: null, table: null })
        setShowChairs(value && chairsWithInventory.length > 0)
    }

    const loadChairsOnAllFloorsIfNeeded = useCallback(() => {
        if (building && building.floors.length > 0 && building.floors[0].hasChairs === undefined) {
            const tasks = building.floors.map((f) => floorPlanService.getFloorPlan(f.id,
                [FloorChildren.FLOOR, FloorChildren.OBJECTS],
                true))
            Promise.allSettled(tasks).then((results: PromiseSettledResult<FloorPlanData>[]) => {
                results.forEach((result) => {
                    if (result.status === 'fulfilled') {
                        const floorPlan = result.value
                        const floor = building.floors.find((f) => f.id === floorPlan.floor?.uuid)
                        if (floor) {
                            floor.hasChairs = floorPlan.objects.filter((o) => o.inventoryId && o.objectType === 'CHAIR').length > 0
                        }
                    }
                })
                setFloorChairsLoaded(true)
            })
        }
    }, [building, floorPlanService, setFloorChairsLoaded])

    const onFloorLoad = useCallback((event: FloorPlanLoadEvent) => {
        setAnnotations([]);
        setObjects([])

        if (event.floorPlan) {
            setIsErrorLoading(false)
            if (
                !event.floorPlan.rooms?.length && !event.floorPlan.doors?.length && !event.floorPlan.objects?.length
                && !event.floorPlan.portals?.length && !event.floorPlan.walls?.length
            ) {
                setIsFloorEmpty(true)
            } else {
                setIsFloorEmpty(false)
            }

            _setMappedToRevitFromLocalStorage()
            let _chairsWithInventory = event.floorPlan.objects.filter((object) => object.inventoryId)
            setRooms(event.floorPlan.rooms);
            setChairsWithInventory(_chairsWithInventory)
            _setSelectedFeature({ table: null, chair: null, room: null })

            setObjects && setObjects(
                event.floorPlan.objects.filter(object =>
                    object.objectType === 'TABLE'
                    || object.objectType === 'CHAIR'
                )
            )
        } else {
            setIsErrorLoading(true)
            // history.push('/search')
        }
        _setLoading(false)
        loadChairsOnAllFloorsIfNeeded()
    }, [_setMappedToRevitFromLocalStorage, setRooms, setChairsWithInventory, _setSelectedFeature, setObjects, _setLoading, loadChairsOnAllFloorsIfNeeded])

    function searchComponent() {
        const label = showChairs ? "Chair" : "Room"
        const placeholder = showChairs ? "Search for a chair" : "Search for a room"
        let results: { id: string, label: string, selection: SelectedFeature }[]
        if (showChairs) {
            results = chairsWithInventory
                ?.filter((chair) => getObjectName(chair)?.toLowerCase().includes(searchText.toLowerCase()))
                .slice(0, 10)
                .map((chair) => {
                    return {
                        id: chair.uuid,
                        label: getObjectName(chair) || "",
                        selection: { table: null, chair: chair, room: null }
                    }
                })
                || []
        } else {
            results = rooms
                ?.filter((_room) => _room.roomNumber.toLowerCase().includes(searchText.toLowerCase()))
                .slice(0, 10)
                .map((room) => {
                    return {
                        id: room.uuid,
                        label: room.roomNumber,
                        selection: { table: null, chair: null, room: { room: room, rooms: rooms } }
                    }
                })
                || []
        }
        return <Input
            className="room-input-container"
            label={label}
            placeholder={placeholder}
            value={searchText}
            onChange={(val: string) => {
                if (val) {
                    setSearchText(val);
                } else {
                    setSearchText("");
                    _setSelectedFeature({ table: null, chair: null, room: null })
                }
            }}
        >
            <div
                id='select-popup'
                className={searchText ? "result" : 'display-none'}>
                <div>
                    {
                        results.map((item) => {
                            return <p
                                key={item.label}
                                className="result-list-item"
                                onClick={() => {
                                    setSearchText("");
                                    _setSelectedFeature(item.selection)
                                    setZoomToFeatureId(item.id)
                                }}
                            >
                                {item.label}
                            </p>
                        })
                    }
                </div>
            </div>
        </Input>
    }

    const isVisualModelValidationComplete = () => {
        return buildingState === VisualValidationBuildingState.APPROVED ||
            buildingState === VisualValidationBuildingState.REJECTED ||
            buildingState === VisualValidationBuildingState.BUSINESS_REVIEW_APPROVED
    }

    const selectedIds: string[] = []
    const id = selectedFeature.chair?.uuid || selectedFeature.room?.room.uuid
    if (id) selectedIds.push(id)

    return (
        <div className="App">
            <div className={buildingState === VisualValidationBuildingState.NO_HARVEST ? "display-none" : "visual-validation-status-link text-small"}>
                {
                    isVisualModelValidationComplete()
                        ? 'Visual model validation complete'
                        : 'Visual model validation available'
                }
                <a className="text-small" href={'/visual-validation/' + buildingId + '/' + floor?.id}> here. </a>
                / Compare with previous harvest
                <a className="text-small" href={'/harvest-review/' + buildingId + '/' + floor?.id}> here.</a>
            </div>
            <div className="container">
                <div className="inventory-header">
                    <div className="inventory-header-row-top">
                        <div className="breadcrumb">
                            <span
                                className="breadcrumb-back text-small"
                                onClick={() => {
                                    clean();
                                    history.push('/search')
                                }}
                            > ⇽ Back to search</span>
                            <span
                                className="breadcrumb-current text-small"
                            >
                                {
                                    " / " + (building?.name || "")
                                }
                            </span>
                        </div>
                        <form className="header-logout">

                            <div className="username text-small text-end"> {user?.email} </div>

                            <input className="floor-plan-btn secondary logout text-end" type="button" value="Logout"
                                onClick={() => logout({ returnTo: window.location.origin })}
                            />
                        </form>

                        {
                            error?.type === 'download'
                                ? (
                                    <p className="text-small download-error">
                                        Download has failed. Try again or <span> </span>
                                        <a className="link text-small" href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                                            report this issue.
                                        </a>
                                    </p>

                                )
                                : <></>
                        }

                    </div>

                    <div className="inventory-header-row">
                        <div className="change-floor">
                            <Select
                                styles={customStyles}
                                className="change-floor-select"
                                options={
                                    (building?.floors || [])
                                        .map((_floor) => {
                                            const label = _floor.hasChairs ? _floor.label + ' 🪑' : _floor.label
                                            return { value: _floor.label, label: label, data: _floor }
                                        })
                                }
                                value={floor ? { value: floor.label, label: floor.label, data: floor } : undefined}
                                onChange={(_floor) => {
                                    if (_floor) {
                                        _cleanError()
                                        _setFloor(_floor.data);
                                        history.push('/building/' + buildingId + '/' + _floor.data.id)
                                    }
                                }}
                            />
                        </div>
                        <div className="toggle-border">
                            <input className={
                                showChairs ? "floor-plan-btn secondary toggle-left" : "floor-plan-btn toggle-left"
                            } type="button" value={"Room View"}
                                onClick={() => toggleShowChairs(false)} />
                            <input className={
                                showChairs
                                    ? "floor-plan-btn toggle-right"
                                    : chairsWithInventory.length > 0 ?
                                        "floor-plan-btn secondary toggle-right"
                                        : "floor-plan-btn disabled toggle-right"
                            }
                                type="button"
                                value={chairsWithInventory.length > 0 ? "Chair View" : "No Chairs"}
                                onClick={() => toggleShowChairs(true)} />
                        </div>
                        {searchComponent()}
                        { 
                            (objects) && (
                                <div className="hide-furniture-building ray-checkbox">
                                    <input
                                        id="checkbox-1"
                                        name="checkbox-button-story"
                                        type="checkbox"
                                        className="ray-checkbox__input"
                                        checked={showFurniture}
                                        onChange={() => setShowFurniture(prev => !prev)}
                                    />
                                    <label className="ray-checkbox__label" htmlFor="checkbox-1">
                                        Show furniture
                                    </label>
                                </div>
                            )
                        }
                        <div className="download-wrapper">
                            <input
                                className="download floor-plan-btn secondary"
                                onClick={downloadRevitRoomScheduleXLSX}
                                value={(loading as any)?.type === 'download' ? "Downloading files" : "Download files"}
                                type="button"
                            />
                        </div>
                    </div>
                </div>

                <div className={floor?.label ? "inventory-summary" : "display-none"}>
                    <div className="inventory-summary-name text-small">
                        {floor?.label}
                    </div>
                    <div className="inventory-summary-item">
                        <span>
                            <img src={doorIcon.default} alt="" className="inventory-summary-item-icon" />
                        </span>
                        <span className="text-small">
                            ROOMS MAPPED
                        </span>
                        <span className="text-small"> {mappedToRevit?.rooms.length || '0'}/{rooms.length} </span>
                    </div>
                    <div className="inventory-summary-item">
                        <span>
                            <img src={chairIcon.default} alt="" className="inventory-summary-item-icon" />
                        </span>
                        <span className="text-small">
                            CHAIRS MAPPED
                        </span>
                        <span className="text-small"> {mappedToRevit?.chairs.length || '0'}/{chairsWithInventory.length} </span>
                    </div>
                </div>

                <FloorErrorEmpty
                    show={isFloorEmpty}
                />

                <FloorErrorLoading
                    show={isErrorLoading}
                    retryTimes={retryTimes}
                />

                <div
                    className={selectedFeature.room || selectedFeature.chair ? "inventory-info-section" : 'display-none'}
                >
                    <InfoSection
                        onChange={() => {
                            setObjects && setObjects((prev) => lodash.clone(prev));
                        }}
                    />
                </div>
            </div>

            <div className="header-line" />
            {
                building ? (
                    <div className="floor-plan-demo">
                        <div className="floor-plan-demo-body">
                            <ReactFloorPlan
                                config={config}
                                mapStyle={FloorPlanBaseMapStyle.REVIT}
                                floorId={floor?.id || ""}
                                children={floorPlanChildren}
                                onWillLoad={() => _setLoading(true)}
                                onLoad={onFloorLoad}
                                selectedIds={selectedIds}
                                annotations={showChairs || showFurniture ? annotations : []}
                                roomHoverEnabled={!showChairs}
                                zoomToFeatureId={zoomToFeatureId ? { id: zoomToFeatureId } : null}
                                onClick={mapClick}
                                styles={customRoomStyle}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
            <Loader
                show={loading}
            />
        </div >
    );
}



export default withRouter(Building);
