import {FPObject, FPRoom} from "@wework/react-floor-plan-sdk";

export type ACTION<T> = {
    type: string,
    payload?: T
};

export type DispatchType<T> = (args: ACTION<T>) => any;

export type STATE = {
    searchResults: SearchResult[],
    recentSearchResults: SearchResult[],
    buildingResult: Building | null,
    floor: Floor | null,
    buildingId: string | null,
    buildingLastUpdate: BuildingLastUpdate | null,
    mappedToRevit: {
        rooms: string[],
        chairs: string[],
    },
    selectedFeature: SelectedFeature,
    visualValidationState: VisualValidationStateItem[],
    floorState: VisualValidationStateItem[],
    buildingState: VisualValidationBuildingState,
    loading: boolean,
    error: any,
};

export interface SearchResult {
    algoliaId: string,
    name: string,
    address?: any,
    code?: string,
}

export type Floor = {
    id: string,
    name: string,
    label: string,
    hasChairs?: boolean
}

export type Building = {
    id: string,
    name: string,
    floors: Floor[]
}

export type BuildingLastUpdate ={
    "createdAt": string,
    "updatedAt": string
}

export type MappedToRevit = {
    rooms: string[],
    chairs: string[]
};

export type SelectedFeature = {
    table: FPObject | null,
    chair: FPObject | null,
    room: { room: FPRoom, rooms: FPRoom[] } | null
}

export enum VisualValidationBuildingState {
    NO_HARVEST = 'no_harvest',
    READY = 'ready_for_visual_validation',
    APPROVED = 'ready_for_business_review',
    REJECTED = 'visual_validation_rejected',
    BUSINESS_REVIEW_APPROVED = 'record_model_approved'
}

export type VisualValidationStateItem = {
    id: string,
    prettyName: string,
    approved?: boolean
}