import React from 'react';
import './Modal.css';

const closeIcon = require('../../assets/images/close.svg');

type ModalType = {
    show: boolean
    children: JSX.Element[] | JSX.Element,
    onClose: () => void,
    actions?: JSX.Element[] | JSX.Element | null
}

const Modal = React.memo(function ({
    show,
    children,
    onClose,
    actions
}: ModalType) {
    if (!show) return <></>;
    return (
        <>
            <div className="modal-form">
                <div className="modal-form-header">
                    <img src={closeIcon.default} alt="X"
                        className="modal-close"
                        onClick={onClose}
                    />
                </div>
                <div className="modal-form-body">
                    {children}
                </div>

                {
                    actions
                        ? actions
                        : (
                            <div className="modal-form-footer">
                                <input className="floor-plan-btn secondary" type="button" value="OK"
                                    onClick={onClose}
                                />
                            </div>
                        )
                }
            </div>
            <div className="modal-overlay"></div>
        </>
    );
})

export default Modal;