/* eslint-disable react-hooks/exhaustive-deps */
import { withRouter } from 'react-router-dom'
import { Dispatch } from "redux"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
    Annotation,
    FloorChildren,
    FloorPlanBaseMapStyle,
    FloorPlanConfig,
    FloorPlanEvent,
    FloorPlanLoadEvent,
    FPObject,
    PolygonAnnotation,
    PolygonAnnotationStyle,
    ReactFloorPlan,
} from "@wework/react-floor-plan-sdk";
import { Floor, SelectedFeature, STATE, VisualValidationBuildingState, VisualValidationStateItem } from "../../store/types";
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useState } from 'react';
import lodash from 'lodash';
import { CHAIR_STYLES, ERROR_SLACK_CHANNEL, FLOOR_PLAN_ENVIRONMENT, TABLE_STYLES, VISUAL_VALIDATION_PAGE_STATE } from '../../constants';
import {
    fetchBuildingLastUpdate,
    fetchBuildingResult,
    loadBuildingState,
    loadFloorState,
    setBuildingId,
    setBuildingState,
    setError,
    setFloor,
    setFloorState,
    setSelectedFeature
} from '../../store/actions';
import Modal from '../../components/Modal/Modal';

import './VisualValidation.css';
import FloorErrorEmpty from '../../components/floorErrorEmpty/FloorErrorEmpty';
import FloorErrorLoading from '../../components/floorErrorLoading/FloorErrorLoading';
import { getFormattedDate } from '../../helpers/helpers';
import InfoSection from '../../components/InfoSection/InfoSection';

const viewFloorIcon = require('../../assets/images/open16.png');
const circleLightIcon = require('../../assets/images/check-circle-light.png');
const circleDarkIcon = require('../../assets/images/check-circle-dark.png');

const floorPlanChildren: FloorChildren[] = [FloorChildren.FLOOR, FloorChildren.OBJECTS, FloorChildren.ROOMS, FloorChildren.WALLS, FloorChildren.DOORS]

function VisualValidation({ history, match }: any) {
    const [annotations, setAnnotations] = useState<Annotation[]>([]);
    const [objects, setObjects] = useState<FPObject[]>([]);
    const [pageStatus, setPageStatus] = useState<VISUAL_VALIDATION_PAGE_STATE>(VISUAL_VALIDATION_PAGE_STATE.ANY);
    const [token, setToken] = useState("")
    const [isErrorLoading, setIsErrorLoading] = useState(false)
    const [isFloorEmpty, setIsFloorEmpty] = useState(false)
    const [retryTimes, setRetryTimes] = useState(+(localStorage.getItem('retry') || 0))

    const dispatch: Dispatch<any> = useDispatch();
    const _getBuildingResult = React.useCallback((id: string, token: string) => dispatch(fetchBuildingResult(id, token)), [dispatch]);
    const _setFloor = React.useCallback((data: Floor) => dispatch(setFloor(data)), [dispatch]);
    const _setBuildingId = React.useCallback((value: string) => dispatch(setBuildingId(value)), [dispatch])
    const _getBuildingLastUpdate = React.useCallback((id: string, token: string) => dispatch(fetchBuildingLastUpdate(id, token)), [dispatch]);
    const _loadFloorState = React.useCallback((buildingId: string, token: string) => dispatch(
        loadFloorState(buildingId, token)), [dispatch]);
    const _setFloorState = React.useCallback((
        buildingId: string, floor: Floor, approved: boolean, token: string
    ) =>
        dispatch(setFloorState(buildingId, floor, approved, token)), [dispatch]);

    const _loadBuildingState = React.useCallback((buildingId: string, token: string) => dispatch(
        loadBuildingState(buildingId, token)), [dispatch]);
    const _setBuildingState = React.useCallback((buildingId: string, status: VisualValidationBuildingState, token: string) => dispatch(
        setBuildingState(buildingId, status, token)), [dispatch]);
    const _setSelectedFeature = React.useCallback((data: SelectedFeature) => dispatch(setSelectedFeature(data)), [dispatch]);

    // setBuildingState
    const _cleanError = React.useCallback(() => dispatch(setError(null)), [dispatch])

    const selectedFeature: SelectedFeature = useSelector((state: STATE) => state.selectedFeature, shallowEqual);
    const building = useSelector((state: STATE) => state.buildingResult, shallowEqual);
    const floor = useSelector((state: STATE) => state.floor, shallowEqual);
    const buildingId = useSelector((state: STATE) => state.buildingId, shallowEqual);
    const buildingLastUpdate = useSelector((state: STATE) => state.buildingLastUpdate, shallowEqual);
    const floorState = useSelector((state: STATE) => state.floorState, shallowEqual);
    const buildingState: VisualValidationBuildingState = useSelector((state: STATE) => state.buildingState, shallowEqual);
    const error = useSelector((state: STATE) => state.error, shallowEqual);

    const { getAccessTokenSilently } = useAuth0();
    const config: FloorPlanConfig = {
        environment: FLOOR_PLAN_ENVIRONMENT,
        auth0TokenProvider: getAccessTokenSilently,
        displayUnapprovedLatestHarvestData: true,
        includeInventoryData: true
    }

    const isFinished = (): boolean => {
        if (!building) return false
        const floorIds = new Set(building.floors.map(f => f.id))
        const floorsWithStatusCount = floorState
            .filter(item => floorIds.has(item.id) && (item.approved === true || item.approved === false))
            .length

        return floorsWithStatusCount === building.floors.length
    }

    const getFloorState = (_floor: Floor): boolean | undefined => {
        const state = floorState?.filter((_state: VisualValidationStateItem) => (
            _state.id === _floor?.id
            || _state.prettyName === _floor?.label
        ))?.[0]
        return state?.approved
    }

    const convertFPObjectsToPolygonAnnotation = (objects: FPObject[]): PolygonAnnotation[] => {
        return objects.map((object) => {
            const id = object.uuid as string;
            const type = object.objectType
            let newStyle: PolygonAnnotationStyle
            if (type === 'CHAIR') {
                newStyle = CHAIR_STYLES.WITHOUT_INVENTORY
            } else {
                newStyle = TABLE_STYLES
            }

            const annotation: PolygonAnnotation = {
                id,
                type: 'Feature' as const,
                geometry: {
                    type: 'Polygon' as const,
                    coordinates: [object.projectedBoundariesInFeet?.outerBoundary?.pointsList.map(p => [p.x, p.y]) || []]
                        .concat(
                            object.projectedBoundariesInFeet?.innerVoidBoundariesList?.map((boundary) =>
                                boundary.pointsList.map(p => [p.x, p.y]) || []
                            ) || []
                        ),
                },
                properties: {
                    type,
                    inventoryId: object.inventoryId,
                    deskNumber: object.objectIdentifier,
                    label: '',
                    ...newStyle
                }
            }

            if (object.objectType === "CHAIR" && object.inventoryId && object.objectIdentifier.length) {
                annotation.properties.label = object.objectIdentifier
            }

            return annotation
        })
            .sort((a) => {
                if (a.properties.type === 'CHAIR') return 1
                return -1
            })
    }

    const onFloorLoad = useCallback((event: FloorPlanLoadEvent) => {
        setAnnotations([]);
        setObjects([])
        if (event.floorPlan) {
            setIsErrorLoading(false)
            if (
                !event.floorPlan.rooms?.length && !event.floorPlan.doors?.length && !event.floorPlan.objects?.length
                && !event.floorPlan.portals?.length && !event.floorPlan.walls?.length
            ) {
                setIsFloorEmpty(true)
            } else {
                setIsFloorEmpty(false)
            }
            setObjects && setObjects(
                event.floorPlan.objects.filter((object: { objectType: string; }) =>
                    object.objectType === 'TABLE'
                    || object.objectType === 'CHAIR'
                )
            )
        } else {
            setIsErrorLoading(true)
        }
    }, [setObjects,])

    const mapClick = (e: FloorPlanEvent) => {
        const _room = e.features.rooms?.[0]

        const isRoomSelected = _room?.uuid && _room?.uuid === selectedFeature.room?.room?.uuid;

        if (isRoomSelected) {
            _setSelectedFeature({ table: null, chair: null, room: null })
        } else {
            _setSelectedFeature({
                table: null,
                chair: null,
                room: { room: _room || null, rooms: e.features.rooms || null } || null
            })
        }
    }

    const getFloorSection = (_floor: Floor) => (
        <div key={_floor.id}
            className="visual-left-panel-floor"
        >
            <div className="visual-left-panel-floor-wrapper">
                <span className="visual-left-panel-floor-title text-small"
                    onClick={() => {
                        _setFloor(_floor)
                        history.push(
                            '/visual-validation/' + buildingId + '/' + _floor.id
                        );
                    }}
                >
                    {_floor?.label}
                </span>
                <div className="visual-left-panel-floor-status-wrapper">
                    <span
                        className={getFloorState(_floor) === true
                            ? "visual-left-panel-floor-title-status" : "display-none"}
                    >
                        APPROVED
                    </span>
                    <span
                        className={getFloorState(_floor) === false
                            ? "visual-left-panel-floor-title-status" : "display-none"}
                    >
                        REJECTED
                    </span>
                    <span className={(
                        floorState?.find(f => f.id === _floor.id)?.approved !== true
                        && floorState?.find(f => f.id === _floor.id)?.approved !== false
                    ) ? "visual-left-panel-floor-icon" : "display-none"}>
                        <img src={circleLightIcon.default} alt="" className="view-floor-icon" />
                    </span>
                    <span className={(
                        floorState?.find(f => f.id === _floor.id)?.approved === true
                        || floorState?.find(f => f.id === _floor.id)?.approved === false
                    ) ? "visual-left-panel-floor-icon" : "display-none"}>
                        <img src={circleDarkIcon.default} alt="" className="view-floor-icon" />
                    </span>
                </div>
            </div>
            <div className="visual-left-panel-floor-view"
                onClick={() => {
                    const win = window.open('/building/' + buildingId + '/' + _floor.id, "_blank");
                    win?.focus();
                }}
            >
                <img src={viewFloorIcon.default} alt="" className="view-floor-icon" />
                View floor plan mapping
            </div>
            <div
                className={(
                    (floor?.id === _floor.id)
                    && pageStatus !== VISUAL_VALIDATION_PAGE_STATE.APPROVED
                    && pageStatus !== VISUAL_VALIDATION_PAGE_STATE.REJECTED
                    && pageStatus !== VISUAL_VALIDATION_PAGE_STATE.CLOSE
                ) ? "visual-left-panel-floor-actions" : "display-none"}
            >
                <input className="floor-plan-btn secondary reject" type="button" value="Reject"
                    disabled={floorState?.find(f => f.id === _floor.id)?.approved === false}
                    onClick={() => {
                        _setFloorState(
                            buildingId as string, _floor, false, token
                        )
                    }} />
                <input className="floor-plan-btn secondary approve" type="button" value="Approve"
                    disabled={floorState?.find(f => f.id === _floor.id)?.approved === true}
                    onClick={() => {
                        _setFloorState(
                            buildingId as string, _floor, true, token
                        )
                    }} />
            </div>
        </div>
    )

    useEffect(() => {
        _setBuildingId(match?.params?.building)
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])

    useEffect(() => {
        if (buildingState === VisualValidationBuildingState.APPROVED && pageStatus !== VISUAL_VALIDATION_PAGE_STATE.CLOSE) {
            setPageStatus(VISUAL_VALIDATION_PAGE_STATE.APPROVED)
        }
        if (buildingState === VisualValidationBuildingState.REJECTED && pageStatus !== VISUAL_VALIDATION_PAGE_STATE.CLOSE) {
            setPageStatus(VISUAL_VALIDATION_PAGE_STATE.REJECTED)
        }
    }, [buildingState])

    useEffect(() => {
        if (error) {
            const retry = +(localStorage.getItem('retry') || 0) + 1
            localStorage.setItem('retry', '' + retry)
            setRetryTimes(retry)
            setIsErrorLoading(true)
            _cleanError()
        } else {
            _cleanError()
        }
    }, [error])

    useEffect(() => {
        if (!buildingId) return;
        getAccessTokenSilently().then((_token) => {
            setToken(_token)
            if (building?.id !== buildingId) _getBuildingResult(buildingId, _token)
            _loadBuildingState(buildingId, _token)
            _getBuildingLastUpdate(buildingId, _token)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingId])

    useEffect(() => {
        if (!building?.floors?.length) return;

        const _floor = building?.floors.filter((floor) => floor.id === match?.params?.floor)[0] || building?.floors[0];
        _setFloor(_floor)

        history.push(
            '/visual-validation/' + building.id + '/' + _floor.id
        );

        _loadFloorState(building.id, token)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [building])

    useEffect(lodash.debounce(() => { // eslint-disable-line react-hooks/exhaustive-deps
        if (!objects.length) return

        setAnnotations && setAnnotations(
            convertFPObjectsToPolygonAnnotation(objects)
        )
    }, 200), [objects])

    const selectedIds: string[] = []
    const id = selectedFeature.room?.room?.uuid
    if (id) selectedIds.push(id)

    return (
        <div className="visual-validation">
            <div className="visual-validation-container">
                <div className="visual-left-panel">
                    <div className="visual-validation-wrapper-status">
                        <span className={
                            buildingState === VisualValidationBuildingState.APPROVED || buildingState === VisualValidationBuildingState.REJECTED
                                ? "visual-validation-status done"
                                : "visual-validation-status"
                        }>
                            {
                                buildingState === VisualValidationBuildingState.APPROVED || buildingState === VisualValidationBuildingState.REJECTED
                                    ? 'DONE'
                                    : 'IN REVIEW'
                            }
                        </span>
                        <span
                            className={buildingState === VisualValidationBuildingState.APPROVED
                                ? "visual-left-panel-floor-title-status" : "display-none"}
                        >
                            APPROVED
                        </span>
                        <span
                            className={buildingState === VisualValidationBuildingState.REJECTED
                                ? "visual-left-panel-floor-title-status" : "display-none"}
                        >
                            REJECTED
                        </span>
                    </div>
                    <div className="visual-validation-current text-small">
                        <div>
                            {
                                building?.name
                            }
                        </div>
                        <div className={buildingLastUpdate?.updatedAt ? '' : 'display-none'}>
                            <span className="text-small">
                                {
                                    'Harvested on: '
                                }
                            </span>
                            <span className="harvested-on-date text-small">
                                {
                                    getFormattedDate(buildingLastUpdate?.updatedAt || null, 'MM/DD/YYYY, h:mm')
                                }
                            </span>
                        </div>
                    </div>
                    <div className={
                        (
                            pageStatus !== VISUAL_VALIDATION_PAGE_STATE.APPROVED
                            && pageStatus !== VISUAL_VALIDATION_PAGE_STATE.REJECTED
                            && pageStatus === VISUAL_VALIDATION_PAGE_STATE.CLOSE
                        ) ? "" : "display-none"}>
                        <div className="visual-validation-done-title text-small">
                            All floors have been checked &#127881;
                        </div>
                        <div className="text-small">
                            Submit this floor as "done" and make sure you have logged all issues found in the
                            original Slack message for the successful harvest.
                        </div>
                        <input className="floor-plan-btn done-after-close" type="button" value="Submit"
                            onClick={() => {
                                const approved = floorState.every(f => f.approved)
                                const state = approved ? VisualValidationBuildingState.APPROVED : VisualValidationBuildingState.REJECTED
                                _setBuildingState(building?.id || '', state, token)
                            }}
                        />
                    </div>
                    <div className="visual-validation-floors-wrapper">
                        {
                            building?.floors
                                ?.map((_floor) => getFloorSection(_floor))
                        }
                    </div>
                </div>
                {
                    building ? (
                        <div className="floor-plan-map">
                            <div className="floor-plan-demo-body">
                                <ReactFloorPlan
                                    config={config}
                                    mapStyle={FloorPlanBaseMapStyle.REVIT}
                                    floorId={floor?.id || ""}
                                    children={floorPlanChildren}
                                    annotations={annotations || []}
                                    selectedIds={selectedIds}
                                    onLoad={onFloorLoad}
                                    onClick={mapClick}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div >

            <FloorErrorEmpty
                show={isFloorEmpty}
            />
            <FloorErrorLoading
                show={isErrorLoading}
                retryTimes={retryTimes}
            />

            <div
                className={selectedFeature.room?.room ? "info-section-wrapper" : 'display-none'}
            >
                <InfoSection
                    showHasInventory={false}
                    showCopyEmail={false}
                />
            </div>

            <Modal
                show={
                    isFinished() && pageStatus === VISUAL_VALIDATION_PAGE_STATE.ANY
                }
                onClose={() => { }}
                actions={
                    <div className="modal-submin-title-actions">
                        <input className="floor-plan-btn secondary" type="button" value="Close"
                            onClick={() => {
                                setPageStatus(VISUAL_VALIDATION_PAGE_STATE.CLOSE)
                            }} />
                        <input className="floor-plan-btn done" type="button" value="Submit"
                            onClick={() => {
                                const approved = floorState?.every(f => f.approved)
                                const state = approved ? VisualValidationBuildingState.APPROVED : VisualValidationBuildingState.REJECTED
                                _setBuildingState(building?.id || '', state, token)
                            }} />
                    </div>
                }
            >
                <div className="modal-submin-title">
                    All floors have been checked &#127881;
                </div>
                <div className="text-small">
                    Submit this floor as "done" and make sure you have logged all issues found in the
                    original Slack message for the successful harvest.
                </div>
            </Modal>

            <Modal
                show={
                    error?.type === 'status-floor-error'
                }
                onClose={() => { _cleanError() }}
            >
                <div className="modal-submin-title">
                    Whoops! We're having some network issues.
                </div>
                <div className="text-small">
                    Try reloading the page or <span> </span>
                    <a href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                        report this issue
                    </a>
                </div>
            </Modal>
            <Modal
                show={
                    error?.type === 'status-building-error'
                }
                onClose={() => { _cleanError() }}
            >
                <div className="modal-submin-title">
                    Whoops! We're having some network issues.
                </div>
                <div className="text-small">
                    Try resubmitting or <span> </span>
                    <a href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                        report this issue
                    </a>
                </div>
            </Modal>
        </div >
    );
}



export default withRouter(VisualValidation);

