/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ChairsList, HarvestDataItem, HarvestInfo } from "../helpers";

export enum FilterMetric {
    TOTAL_CHANGES = 'TOTAL CHANGES',
    ROOMS_CREATED = 'ROOMS CREATED',
    ROOMS_DELETED = 'ROOMS DELETED',
    ROOMS_UPDATED = 'ROOMS UPDATED',
    UNLINKED_ROOM_INV = 'UNLINKED ROOM INV',
    UNLINKED_CHAIR_INV = 'UNLINKED CHAIR INV',
    WORK_UNITS = 'WORK UNITS',
};

function HarvestReviewDiffSummaryMetrics({ chairsList, harvestData, active, onClick }: { 
    active?: FilterMetric,
    onClick: (active: FilterMetric) => void,
    harvestData: HarvestDataItem[],
    chairsList: ChairsList,
}) {
    const created = HarvestInfo.howManyRoomsAdded(harvestData);
    const deleted = HarvestInfo.howManyRoomsRemoved(harvestData);
    const updated = HarvestInfo.howManyRoomsHaveDifferences(harvestData);
    const unlinkedRooms = HarvestInfo.howManyRoomsUnlinked(harvestData);
    const unlinkedChairs = HarvestInfo.howManyChairsUnlinked(chairsList);
    const workUnits = HarvestInfo.howManyWorkUnitsHaveDifferences(harvestData);
    // const workUnitsDiff = HarvestInfo.getWorkUnitsDiff(harvestData);

    return (
        <div className="diff-summary-metrics">
                <div 
                    className={ 
                        active === FilterMetric.TOTAL_CHANGES
                            ? "diff-summary-metric diff-summary-metric-first diff-summary-metric-active" 
                            : "diff-summary-metric diff-summary-metric-first"
                    }
                    onClick={() => onClick(FilterMetric.TOTAL_CHANGES)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.TOTAL_CHANGES}
                    </div>
                    <div className="diff-summary-metric-value-container">
                        <div className="diff-summary-metric-value">
                            { harvestData.length }
                        </div>
                    </div>
                </div>
                <div 
                    className={ active === FilterMetric.ROOMS_CREATED ? "diff-summary-metric diff-summary-metric-active" : "diff-summary-metric"}
                    onClick={() => onClick(FilterMetric.ROOMS_CREATED)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.ROOMS_CREATED}
                    </div>
                    <div className="diff-summary-metric-value-container">
                        <div className={
                            created > 0 ? "diff-summary-metric-value more" : "diff-summary-metric-value"
                        }>
                            {
                                created
                            }
                        </div>
                    </div>
                </div>
                <div 
                    className={ active === FilterMetric.ROOMS_DELETED ? "diff-summary-metric diff-summary-metric-active" : "diff-summary-metric"}
                    onClick={() => onClick(FilterMetric.ROOMS_DELETED)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.ROOMS_DELETED}
                    </div>
                    <div className="diff-summary-metric-value-container">
                        <div className={
                            deleted > 0 ? "diff-summary-metric-value less" : "diff-summary-metric-value"
                        }>
                            {
                                deleted
                            }
                        </div>
                    </div>
                </div>
                <div 
                    className={ active === FilterMetric.ROOMS_UPDATED ? "diff-summary-metric diff-summary-metric-active" : "diff-summary-metric"}
                    onClick={() => onClick(FilterMetric.ROOMS_UPDATED)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.ROOMS_UPDATED}
                    </div>
                    <div className="diff-summary-metric-value-container">
                        <div className={
                            updated > 0 ? "diff-summary-metric-value more" : "diff-summary-metric-value"
                        }>
                            {
                                updated
                            }
                        </div>
                    </div>
                </div>
                <div 
                    className={ active === FilterMetric.UNLINKED_ROOM_INV ? "diff-summary-metric diff-summary-metric-active" : "diff-summary-metric"}
                    onClick={() => onClick(FilterMetric.UNLINKED_ROOM_INV)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.UNLINKED_ROOM_INV}
                    </div>
                    <div className="diff-summary-metric-value-container">
                        <div className={
                            unlinkedRooms > 0 ? "diff-summary-metric-value less" : "diff-summary-metric-value"
                        }>
                            {
                                unlinkedRooms
                            }
                        </div>
                    </div>
                </div>
                <div 
                    className={ active === FilterMetric.UNLINKED_CHAIR_INV ? "diff-summary-metric diff-summary-metric-active" : "diff-summary-metric"}
                    onClick={() => onClick(FilterMetric.UNLINKED_CHAIR_INV)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.UNLINKED_CHAIR_INV}
                    </div>
                    <div className="diff-summary-metric-value-container">
                        <div className={
                            unlinkedChairs > 0 ? "diff-summary-metric-value less" : "diff-summary-metric-value"
                        }>
                            {
                                unlinkedChairs
                            }
                        </div>
                    </div>
                </div>
                <div 
                    className={ active === FilterMetric.WORK_UNITS
                        ? "diff-summary-metric diff-summary-metric-last diff-summary-metric-active" 
                        : "diff-summary-metric diff-summary-metric-last"
                    }
                    onClick={() => onClick(FilterMetric.WORK_UNITS)}
                >
                    <div className="diff-summary-metric-label">
                        {FilterMetric.WORK_UNITS}
                    </div>
                    <div className={
                            workUnits > 0 ? "diff-summary-metric-value less" : "diff-summary-metric-value"
                        }>
                            {
                                workUnits
                            }
                        </div>
                </div>
        </div>
    );
}

export default React.memo(HarvestReviewDiffSummaryMetrics);
