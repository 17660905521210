/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useState } from 'react';
import { Dispatch } from "redux"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { withRouter } from 'react-router-dom'
import Header from '../../components/Header/Header';
import {
    cleanSearchResult,
    fetchSearchResult,
    setRecentSearchResult,
    setError,
    fetchBuildingResultByAlgoliaId
} from '../../store/actions';
import { SearchResult, STATE } from '../../store/types';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../../components/Loader/Loader';
import Input from '../../components/Input/Input';
import lodash from 'lodash';

import './Search.css';
import { ERROR_SLACK_CHANNEL } from '../../constants';

function displayStringForSearchResult(item: SearchResult): string {
    return item.code ? item.code + ' - ' + item.name : item.name
}

const Search = React.memo(function ({ history }: any) {
    const [value, setValue] = useState("");

    const { getAccessTokenSilently } = useAuth0();

    const dispatch: Dispatch<any> = useDispatch();
    const _getSearchResult = React.useCallback((value: string, token: string) => dispatch(fetchSearchResult(value, token)), [dispatch])
    const _setRecentSearchResult = React.useCallback((result: SearchResult) => dispatch(setRecentSearchResult(result)), [dispatch])
    const _getBuildingResult = React.useCallback((algoliaId: string, token: string) => dispatch(fetchBuildingResultByAlgoliaId(algoliaId, token)), [dispatch]);
    const _cleanSearchResult = React.useCallback(() => dispatch(cleanSearchResult()), [dispatch])
    const _cleanError = React.useCallback(() => dispatch(setError(null)), [dispatch])

    const searchResults: SearchResult[] = useSelector((state: STATE) => state.searchResults, shallowEqual);
    const recentSearchResults: SearchResult[] = useSelector((state: STATE) => state.recentSearchResults, shallowEqual);
    const loading: boolean = useSelector((state: STATE) => state.loading, shallowEqual);
    const error: any | null = useSelector((state: STATE) => state.error, shallowEqual);
    const building = useSelector((state: STATE) => state.buildingResult, shallowEqual);

    const clickOnDocumentHandler = (e: any) => {
        if (!document?.getElementById('search-input-container')?.contains(e.target)) {
            setValue && setValue("");
            _cleanSearchResult();
        }
    }

    useEffect(() => {
        localStorage.setItem('retry', '0') // it is  needed on building page. DM-2311 ticket
        document.addEventListener('click', clickOnDocumentHandler);
        return () => {
            document.removeEventListener("click", clickOnDocumentHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(lodash.debounce(() => {
        if (!searchResults && !value) return;
        if (value) {
            search();
        } else {
            _cleanSearchResult();
        }
    }, 100), [value])

    // observe _getBuildingResult and push
    useEffect( () => {
        if (building?.id) history.push('/building/' + building.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [building])

    const goToMap = (item: SearchResult) => {
        _cleanSearchResult()
        _setRecentSearchResult(item)
        getAccessTokenSilently()
            .then(token => _getBuildingResult(item.algoliaId, token))
    }

    const search = () => {
        getAccessTokenSilently().then(token => {
            _getSearchResult(value, token)
        })
    }

    return (
        <div className="container search">
            <Header />
            <h3 className="search-header text-big">
                Search for a location
            </h3>
            <div className="search-body">
                <Input
                    className="search-input"
                    label="Search"
                    placeholder="Enter an address or location name"
                    value={value}
                    isValidStyle
                    onChange={(val: string) => {
                        _cleanError()
                        setValue(val)
                        if (!val) {
                            _cleanSearchResult();
                        }
                    }}
                >
                    <div
                        id='select-popup'
                        className={searchResults?.length || error ? "result" : 'display-none'}>
                        <div>
                            {
                                !!error
                                    ? (<p
                                        className="result-list-item search-error text-small"
                                        onClick={_cleanError}
                                    >
                                        Whoops! Can't connect to the search API.
                                        <a
                                            className="text-small"
                                            href={ERROR_SLACK_CHANNEL}
                                            target="_blank"
                                            rel="noreferrer"
                                        > Report this issue.</a>
                                    </p>)
                                    : <></>

                            }
                            {
                                searchResults?.map((item) => {
                                    return <p
                                        key={item.algoliaId}
                                        className="result-list-item"
                                        onClick={() => {
                                            _cleanError()
                                            goToMap(item)
                                        }}
                                    >
                                        {displayStringForSearchResult(item)}
                                    </p>
                                })
                            }
                        </div>
                    </div>
                </Input>
            </div>

            <div className={searchResults?.length === 0 && value ? "error result-error" : 'display-none'}>
                Your search did not match any documents.
            </div>

            <div className={recentSearchResults.length ? "recent-result" : 'display-none'}>
                <div className="result-title text-big">
                    Recent searches
                </div>
                <div className="result-list">
                    <div className="result-list-header">
                        Location
                    </div>
                    {
                        recentSearchResults.map((item: any) => {
                            return <p
                                key={item.algoliaId}
                                className="result-list-item-recent"
                                onClick={() => {
                                    _cleanError()
                                    goToMap(item)
                                }}
                            >
                                {displayStringForSearchResult(item)}
                            </p>
                        })
                    }
                </div>
            </div>
            <Loader
                show={loading}
            />
        </div>
    );
})

export default withRouter(Search);