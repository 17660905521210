import xlsx from "xlsx"
import {FloorChildren, FloorPlanData, FloorPlanDataService, FPRoom} from "@wework/react-floor-plan-sdk";
import {Building} from "../store/types";
import {designedAsProgramTypeMapping} from "./RoomProgramTypeMapping";

/*
Name
Number
WW-ProgramType
WW-ProgramOrder
WW-HasWindow
WW-InternalRoomCount
WW-PhysicalDeskCountRoom
WW-WorkUnitCountRoom
WW-GroupId
WW-GroupName
WW-GroupNumber
WW-GroupTypeStargateFloorUuid
WW-PhysicalDeskCountRoom_SRS
WW-WorkUnitCountRoom_SRS
SRS_UUID
 */
interface ExportData {
    Name: string,
    Number: string,
    'WW-ProgramType': string
    'WW-ProgramOrder': number
    'WW-HasWindow': boolean
    'WW-InternalRoomCount': number,
    'WW-PhysicalDeskCountRoom': string,
    'WW-WorkUnitCountRoom': string,
    'WW-GroupId': string,
    'WW-GroupName': string,
    'WW-GroupNumber': string,
    'WW-WorkUnitCountRoom_SRS': number,
    SRS_UUID: string
}


export class RoomScheduleExport {
    service: FloorPlanDataService

    constructor(floorPlanService: FloorPlanDataService) {
        this.service = floorPlanService;
    }

    loadAndPromptDownload(building: Building): Promise<void> {
        return this.loadAllFloorsInBuilding(building)
            .then((floors) => {
                // prompt download: building json
                const json = formatBuildingJSONForDownload(floors, building)
                downloadObjectAsJson(json, `Building: ${building.name} - export.json`)

                // prompt download: building room schedule xlsx
                const rooms = floors.flatMap((d) => d.rooms)
                return createXLSXAndPromptDownload(exportRowsForRooms(rooms), `Building: ${building.name} - Room Schedule.xlsx`)
            })
    }

    private loadAllFloorsInBuilding(building: Building): Promise<FloorPlanData[]> {
        const tasks = building.floors.map((f) => this.service.getFloorPlan(f.id,
            [ FloorChildren.FLOOR, FloorChildren.ROOMS, FloorChildren.OBJECTS],
            true))
        return Promise.all(tasks)
    }
}

export function exportRowsForRooms(rooms: FPRoom[]): ExportData[] {
    return rooms.map((room) => {
        const {type, order} = programTypeAndOrder(room)
        return {
            Name: room.spaceType || '',
            Number: room.roomNumber || '',
            'WW-ProgramType': type,
            'WW-ProgramOrder': order,
            'WW-HasWindow': room.hasWindow || false,
            'WW-InternalRoomCount': room.metrics?.internalRoomCount || 0,
            'WW-PhysicalDeskCountRoom': '',
            'WW-WorkUnitCountRoom': '',
            'WW-GroupId': '',
            'WW-GroupName': '',
            'WW-GroupNumber': '',
            'WW-WorkUnitCountRoom_SRS': room.metrics?.workUnits || 0,
            SRS_UUID: room.uuid,
        }
    })
}

function programTypeAndOrder(room: FPRoom): {type: string, order: number} {
    let programTypeLabel = designedAsProgramTypeMapping.get(room.programType || '') ||
        designedAsProgramTypeMapping.get(room.designedAsProgramType)

    if (programTypeLabel) {
        const map = {
            WE: 1,
            WORK: 2,
            MEET: 3,
            CIRCULATE: 4,
            WASH: 5,
            OPERATE: 6
        }
        for (const [key, value] of Object.entries(map)) {
            if (key === programTypeLabel) return {type: key, order: value}
        }
    }
    return {type: 'UNKNOWN', order: 0}
}

function createXLSXAndPromptDownload(rows: ExportData[], fileName: string): Promise<void> {
    try {
        const workBook = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(workBook, xlsx.utils.json_to_sheet(rows))
        xlsx.writeFile(workBook, fileName)
        return Promise.resolve()
    } catch (e) {
        return Promise.reject(e)
    }
}

function formatBuildingJSONForDownload(floorData: FloorPlanData[], aBuilding: Building): any {
    const building: any = { ...aBuilding }
    building.floors = floorData.map((data) => {
        const floor: any = { ...data.floor }
        delete floor.auditFields
        floor.rooms = data.rooms.map((aRoom) => {
            const room = {...aRoom} as any
            delete room.projectedBoundariesInFeet
            delete room.viewQuality
            delete room.lightQuality
            delete room.obstructions
            delete room.auditFields
            delete room.hasWhiteboard
            delete room.hasTv
            delete room.hasPhone
            delete room.hasVideoConferencing
            delete room.isSellableBookable
            delete room.sourceIdsList
            return room
        })
        floor.objects = data.objects.filter((o) => o.inventoryId && o.objectType === 'CHAIR' )
            .map((anObject) => {
                const object = {...anObject} as any
                delete object.projectedBoundariesInFeet
                delete object.auditFields
                delete object.sourceIdsList
                return object
            })
        return floor
    })
    return building
}

function downloadObjectAsJson(exportObj: any, exportName: string){
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}