/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Doughnut } from 'react-chartjs-2';
import { HarvestDataItem, HarvestInfo } from "../helpers";
import { Chart as ChartJS, ArcElement } from "chart.js";
ChartJS.register(ArcElement)


enum BACKGROUND_COLORS_FOR_CHART {
    WORK = '#D2EBF0',
    WASH = '#C3C3C3',
    MEET = '#B9F0D7',
    CIRCULATE = '#FFF5E1',
    WE = '#FFF5CC',
    OPERATE = '#E1E1E1',
    OTHER = 'white',
}

function HarvestReviewDiffSummary({ harvestData }: { harvestData: HarvestDataItem[] }) {
    const squareFootageMetrics = HarvestInfo.getSquareFootageMetrics(harvestData);

    const data = {
        labels: [],
        datasets: [
            {
                data: [
                    squareFootageMetrics.sum.work, squareFootageMetrics.sum.wash, squareFootageMetrics.sum.meet,
                    squareFootageMetrics.sum.circulate, squareFootageMetrics.sum.we, squareFootageMetrics.sum.operate,
                    squareFootageMetrics.sum.other,
                ],
                backgroundColor: [
                    BACKGROUND_COLORS_FOR_CHART.WORK, BACKGROUND_COLORS_FOR_CHART.WASH, BACKGROUND_COLORS_FOR_CHART.MEET,
                    BACKGROUND_COLORS_FOR_CHART.CIRCULATE, BACKGROUND_COLORS_FOR_CHART.WE, BACKGROUND_COLORS_FOR_CHART.OPERATE,
                    BACKGROUND_COLORS_FOR_CHART.OTHER,
                ],
                borderColor: [
                    'grey'
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
            <div className="diff-summary-usf">
                <div className="diff-summary-usf-label">
                    <div>USF</div>
                    <div className="diff-summary-usf-summary-total">
                        {squareFootageMetrics.total.toFixed(2)}sf
                    </div>
                    <div className={
                        squareFootageMetrics.diff.operate > 0
                            ? "diff-summary-usf-summary-diff more"
                            : squareFootageMetrics.diff.operate < 0
                                ? "diff-summary-usf-summary-diff less"
                                : "diff-summary-usf-summary-diff"
                    }> {squareFootageMetrics.diffTotal.toFixed(2)}sf </div>
                </div>
                <div className="diff-summary-usf-chart">
                    <Doughnut
                        width={60}
                        height={60}
                        options={{
                            responsive: false,
                        }}
                        data={data}
                    />
                </div>
            </div>
    );
}

export default React.memo(HarvestReviewDiffSummary);
