

export const designedAsProgramTypeMapping = new Map<string, string>()

// exported from https://docs.google.com/spreadsheets/d/1D_M3kB7F04oxm2Cc6DoXSei1GuaFMzIQJwrsu6G4fYE/edit#gid=0 via https://csvjson.com/csv2json
const googleSheetExportAsJSON = [
    {
        "DESIGNED_AS_PROGRAM_TYPE": "WASH",
        "COUNT(*)": 15543,
        "MAPPED VALUES": "WASH"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "SERVE",
        "COUNT(*)": 5314,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "Wash",
        "COUNT(*)": 567,
        "MAPPED VALUES": "WASH"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "COMMON",
        "COUNT(*)": 7,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "WORK",
        "COUNT(*)": 105012,
        "MAPPED VALUES": "WORK"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "BREAKOUT",
        "COUNT(*)": 5,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "SERVICE",
        "COUNT(*)": 107,
        "MAPPED VALUES": "OPERATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "OUTDOOR",
        "COUNT(*)": 13,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "PLAY",
        "COUNT(*)": 4,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "Operate",
        "COUNT(*)": 1677,
        "MAPPED VALUES": "OPERATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "COLLABORATE",
        "COUNT(*)": 24,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "EAT & DRINK",
        "COUNT(*)": 395,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "TRANSACT",
        "COUNT(*)": 5,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "RESTORE",
        "COUNT(*)": 18,
        "MAPPED VALUES": "WASH"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "WELLNESS",
        "COUNT(*)": 4,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "F&B",
        "COUNT(*)": 3,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "CIRCULATE",
        "COUNT(*)": 16297,
        "MAPPED VALUES": "CIRCULATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "Work",
        "COUNT(*)": 2774,
        "MAPPED VALUES": "WORK"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "Circulate",
        "COUNT(*)": 661,
        "MAPPED VALUES": "CIRCULATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "VERTICAL",
        "COUNT(*)": 81,
        "MAPPED VALUES": "CIRCULATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "TEAM HOME",
        "COUNT(*)": 4,
        "MAPPED VALUES": "WORK"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "MEET",
        "COUNT(*)": 29609,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "We",
        "COUNT(*)": 1547,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "MISC",
        "COUNT(*)": 20,
        "MAPPED VALUES": "CIRCULATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "CIRCLUATE",
        "COUNT(*)": 1,
        "MAPPED VALUES": "CIRCULATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "ADDITIONAL",
        "COUNT(*)": 1,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "WE",
        "COUNT(*)": 31834,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "THRIVE",
        "COUNT(*)": 56,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "Meet",
        "COUNT(*)": 1037,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "GATHER",
        "COUNT(*)": 54,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "ADDITIONAL GOOGLE MEET",
        "COUNT(*)": 27,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "VT",
        "COUNT(*)": 11,
        "MAPPED VALUES": "CIRCULATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "RESTROOMS",
        "COUNT(*)": 2,
        "MAPPED VALUES": "WASH"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "we",
        "COUNT(*)": 1,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "OPERATE",
        "COUNT(*)": 24419,
        "MAPPED VALUES": "OPERATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "FOCUS",
        "COUNT(*)": 13,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "BOH",
        "COUNT(*)": 6,
        "MAPPED VALUES": "OPERATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "WORK - DESKS",
        "COUNT(*)": 4,
        "MAPPED VALUES": "WORK"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "",
        "COUNT(*)": 103,
        "MAPPED VALUES": "UNKNOWN"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "INFRASTRUCTURE",
        "COUNT(*)": 198,
        "MAPPED VALUES": "OPERATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "PLANT",
        "COUNT(*)": 30,
        "MAPPED VALUES": "OPERATE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "AMENITY",
        "COUNT(*)": 3,
        "MAPPED VALUES": "WE"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "BREAK OUT",
        "COUNT(*)": 5,
        "MAPPED VALUES": "MEET"
    },
    {
        "DESIGNED_AS_PROGRAM_TYPE": "CIRCULATION",
        "COUNT(*)": 2,
        "MAPPED VALUES": "CIRCULATE"
    }
]

googleSheetExportAsJSON.forEach((item) => {
    designedAsProgramTypeMapping.set(item.DESIGNED_AS_PROGRAM_TYPE, item['MAPPED VALUES'])
})