import React from 'react';
import './Loader.css';

type LoaderType = {
    show: boolean
}

const Loader = React.memo(function ({
    show
}: LoaderType) {
    if (!show) return <></>;

    return (
        <>
            <div className="loader"></div>
            <div className="loader-overlay"></div>
        </>
    );
})

export default Loader;