import {FPObject, IPoint, PolygonAnnotation} from '@wework/react-floor-plan-sdk'
import Color from 'color'

const availableDeskColor = 'blue'


export function styleAnnotationUser(annotation: PolygonAnnotation,
                                    isIncludedInCurrentFilter: boolean) {
    const deskColor = availableDeskColor
    const deskColorDarkened = Color(deskColor).darken(0.2).toString()
    const deskColorDarkened2 = Color(deskColor).darken(0.4).toString()
    const deskColorDarkened3 = Color(deskColor).darken(0.6).toString()

    annotation.properties.style = {
        fill: {
            color: deskColor,
            opacity: isIncludedInCurrentFilter ? 1 : 0.1,
        },
        line: {
            color: deskColorDarkened,
            width: 1,
            opacity: isIncludedInCurrentFilter ? 1 : 0.1,
        }
    }

    if (isIncludedInCurrentFilter) { // disabled, booked and filtered (out) desks cannot be hovered or selected.
        annotation.properties.hoverStyle = {
            fill: {
                color: deskColorDarkened,
                opacity: 1,
            },
            line: {
                color: deskColorDarkened2,
                width: 2,
                opacity: 1,
            }
        }
        annotation.properties.selectedStyle = {
            fill: {
                color: deskColorDarkened2,
                opacity: 1,
            },
            line: {
                color: deskColorDarkened3,
                width: 3,
                opacity: 1,
            }
        }
    } else {
        delete annotation.properties.hoverStyle
        delete annotation.properties.selectedStyle
    }
}

/**
 * @description Calculates the center of a given polygon boundary. If the first and last point are the same, this first/last value is only included in teh average once.
 * @param points - An array of IPoint objects describing a polygon geometry.
 */
export function averagePolygonPoints(points: IPoint[]): IPoint {
    if (points.length === 0) return {x: 0, y: 0}
    const isFirstRepeated = points[0].x === points[points.length-1].x && points[0].y === points[points.length-1].y
    const total: IPoint = {x: 0, y: 0}
    const count = isFirstRepeated ? points.length - 1 : points.length
    points.forEach((point) => {
        total.x += point.x
        total.y += point.y
    })
    if (isFirstRepeated) {
        total.x -= points[0].x
        total.y -= points[0].y
    }
    total.x /= count
    total.y /= count
    return total
}

export function getObjectName(object: FPObject | null): string | undefined {
    return object?.objectIdentifier?.length ? object?.objectIdentifier : object?.inventoryName
}