import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Building from './pages/Building/Building';
import Auth from './pages/Auth/Auth';
import Search from "./pages/Search/Search";
import PrivateRoute from './helpers/PrivateRoute';
import { Auth0Provider } from "@auth0/auth0-react"
import { Provider } from "react-redux";
import STORE from "./store";
import './index.css';
import {
    AUTH_0_CLIENT_ID,
    AUTH_0_DOMAIN,
    AUTH_0_FLOOR_PLAN_SERVICE_AUDIENCE
} from "./constants";
import VisualValidation from "./pages/VisualValidation/VisualValidation";
import HarvestReview from "./pages/HarvestReview/HarvestReview";

export default function Routing() {
    return (
        <Provider store={STORE}>
            <Router>
                <Auth0Provider
                    domain={AUTH_0_DOMAIN}
                    clientId={AUTH_0_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience={AUTH_0_FLOOR_PLAN_SERVICE_AUDIENCE}
                    useRefreshTokens={true}>
                    <div className="mapping-tool" id="mapping-tool">
                        <Switch>
                            <PrivateRoute
                                path='/harvest-review/:building' component={HarvestReview} exact
                            />
                            <PrivateRoute
                                path='/harvest-review/:building/:floor' component={HarvestReview} exact
                            />
                            <PrivateRoute
                                path='/visual-validation/:building' component={VisualValidation} exact
                            />
                            <PrivateRoute
                                path='/visual-validation/:building/:floor' component={VisualValidation} exact
                            />
                            <PrivateRoute
                                path="/building/:building/:floor" component={Building} exact
                            />
                            <PrivateRoute
                                path="/building/:building" component={Building} exact
                            />
                            <PrivateRoute
                                path="/search" component={Search} exact
                            />
                            <Route path='/login' component={(props: { location: Location }) => {
                                const query = props.location.search || '?'
                                // redirect to Auth0 to handle Organization invitations
                                if (query.includes('invitation') && query.includes('organization')) {
                                    window.location.href = 'https://' + AUTH_0_DOMAIN + '/authorize' + query + '&response_type=token&client_id=' + AUTH_0_CLIENT_ID
                                }
                                return (<Auth {...props} />)
                            }} />
                            <Redirect
                                from="*" to="/search"
                            />
                        </Switch>
                    </div>
                </Auth0Provider>
            </Router>
        </Provider>
    );
}