import { FloorPlanEnvironment } from "@wework/react-floor-plan-sdk";

export const FLOOR_PLAN_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === 'production' ?
   FloorPlanEnvironment.RECORD_MODEL_PRODUCTION : FloorPlanEnvironment.RECORD_MODEL_STAGING
 // 'http://localhost:8080' as FloorPlanEnvironment

export const AUTH_0_CLIENT_ID = process.env.REACT_APP_ENVIRONMENT === 'production' ?
    'QKA1DPUZFYSoRMygw2A9bsn54HqS13Pv' : 'e1WQn28sbSM0WTXdSAhceQC4dwSSYu2O'
export const AUTH_0_DOMAIN = process.env.REACT_APP_ENVIRONMENT === 'production' ?
    'record-model-inventory.us.auth0.com' : 'record-model-inventory-dev.us.auth0.com'
export const AUTH_0_FLOOR_PLAN_SERVICE_AUDIENCE = process.env.REACT_APP_ENVIRONMENT === 'production' ?
    'prod-record-model-floor-plan-service' : 'record-model-floor-plan-service'

export const ERROR_SLACK_CHANNEL = 'https://wework.slack.com/archives/C026UFJFPGA'

export enum VISUAL_VALIDATION_PAGE_STATE {
    ANY,
    CLOSE,
    REJECTED,
    APPROVED
}

export const customStyles = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'white',
        padding: '0px',
        // This line disable the blue border
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #3a00ff',
        }
    }),
    menu: (provided: any) => ({
        ...provided, zIndex: 9999
    }),
    option: (provided: any, state: any) => {
        return {
            ...provided,
            padding: '10px 5px',
            backgroundColor: state.isSelected ? '#3a00ff' : 'white',
            color: state.isSelected ? 'white' : 'black'
        };
    },
};

export const customHarvestStyles = {
    ...customStyles,
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'white',
        padding: '0px',
        // This line disable the blue border
        boxShadow: 'none',
        border: '1px solid white',
        '&:hover': {
            border: '1px solid white',
        }
    }),
}

export const COLORS = {
    ROOM: {
        MAPPED: {
            UNSELECTED: {
                FILL: '#CCCCFF', LINE: '#000000',
            },
            SELECTED: {
                FILL: '#0018f5', LINE: '#aaaaaa',
            },
            HOVER: {
                FILL: '#9999ff', LINE: '#aaaaaa',
            },
        },
        CHAIR_MODE: {
            UNSELECTED: {
                FILL: '#ebebeb', LINE: '#aaaaaa',
            }
        },
    },
    CHAIR: {
        NOT_MAPPED: {
            UNSELECTED: {
                FILL: '#ffcecc', LINE: '#666666',
            },
            SELECTED: {
                FILL: '#cc0000', LINE: '#666666',
            },
            HOVER: {
                FILL: '#ff9c99', LINE: '#666666',
            }
        },
        MAPPED: {
            UNSELECTED: {
                FILL: '#737ee6', LINE: '#000000',
            },
            SELECTED: {
                FILL: '#0013c2', LINE: '#666666',
            },
            HOVER: {
                FILL: '#2939cc', LINE: '#666666',
            }
        }
    },
    DESK: {
        FILL: 'white', LINE: '#666666',
    },
}

export const CHAIR_STYLES = {
    NOT_MAPPED: {
        style: {
            fill: {
                color: COLORS.CHAIR.NOT_MAPPED.UNSELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.CHAIR.NOT_MAPPED.UNSELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        },
        hoverStyle: {
            fill: {
                color: COLORS.CHAIR.NOT_MAPPED.HOVER.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.CHAIR.NOT_MAPPED.HOVER.LINE,
                width: 2,
                opacity: 1,
            }
        },
        selectedStyle: {
            fill: {
                color: COLORS.CHAIR.NOT_MAPPED.SELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.CHAIR.NOT_MAPPED.SELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        }
    },
    MAPPED: {
        style: {
            fill: {
                color: COLORS.CHAIR.MAPPED.UNSELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.CHAIR.MAPPED.UNSELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        },
        hoverStyle: {
            fill: {
                color: COLORS.CHAIR.MAPPED.HOVER.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.CHAIR.MAPPED.HOVER.LINE,
                width: 1,
                opacity: 1,
            }
        },
        selectedStyle: {
            fill: {
                color: COLORS.CHAIR.MAPPED.SELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.CHAIR.MAPPED.SELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        }
    },
    WITHOUT_INVENTORY: {
        style: {
            fill: {
                color: COLORS.CHAIR.NOT_MAPPED.UNSELECTED.FILL,
                opacity: 0.1,
            },
            line: {
                color: COLORS.CHAIR.NOT_MAPPED.UNSELECTED.LINE,
                width: 1,
                opacity: 0.2,
            }
        }
    },
}

export const TABLE_STYLES = {
    style: {
        fill: {
            color: COLORS.DESK.FILL,
            opacity: 0.6,
        },
        line: {
            color: COLORS.DESK.LINE,
            width: 1,
            opacity: 0.6,
        }
    }
}

export const ROOM_STYLES = {
    MAPPED: {
        style: {
            fill: {
                color: COLORS.ROOM.MAPPED.UNSELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.ROOM.MAPPED.UNSELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        },
        hoverStyle: {
            fill: {
                color: COLORS.ROOM.MAPPED.HOVER.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.ROOM.MAPPED.HOVER.LINE,
                width: 1,
                opacity: 1,
            }
        },
        selectedStyle: {
            fill: {
                color: COLORS.ROOM.MAPPED.SELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.ROOM.MAPPED.SELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        }
    },
    CHAIR_MODE: {
        style: {
            fill: {
                color: COLORS.ROOM.CHAIR_MODE.UNSELECTED.FILL,
                opacity: 1,
            },
            line: {
                color: COLORS.ROOM.CHAIR_MODE.UNSELECTED.LINE,
                width: 1,
                opacity: 1,
            }
        }
    }
}


export const DISABLED_STYLE = {
    style: {
        fill: {
            color: '#d3d3d3',
            opacity: 1,
        },
        line: {
            color: '#929292',
            width: 1,
            opacity: 1,
        }
    },
    hoverStyle: {
        fill: {
            color: '#d3d3d3',
            opacity: 1,
        },
        line: {
            color: '#6d6d6d',
            width: 3,
            opacity: 1,
        }
    },
    selectedStyle: {
        fill: {
            color: '#808080',
            opacity: 1,
        },
        line: {
            color: '#343434',
            width: 2,
            opacity: 1,
        }
    }
}
