import React from 'react';
import { ERROR_SLACK_CHANNEL } from '../../constants';
import './FloorErrorEmpty.css';

const emptyIcon = require('../../assets/images/48.png');

type FloorEmptyType = {
    show: boolean
}

const FloorErrorEmpty = React.memo(function ({
    show,
}: FloorEmptyType) {
    if (!show) return <></>;
    return (
        <>
            <div className={show ? "floor-empty" : "display-none"}>
                <span>
                    <img src={emptyIcon.default} alt="" className="inventory-summary-item-icon" />
                </span>
                <p className="floor-empty-row floor-empty-title">
                    No map data
                </p>
                <p className="floor-empty-row text-small">
                    Hmm, this floor doesn't have a map.
                </p>
                <p className="text-small">
                    Report this issue.
                </p>
                <div className="floor-empty-actions">
                    <a href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                        <input
                            className="floor-empty-row floor-plan-btn info-section-btn text-small"
                            type="button"
                            value="Report"
                        />
                    </a>
                </div>
            </div>
        </>
    );
})

export default FloorErrorEmpty;