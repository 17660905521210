import { getFromLocalStorage } from "../helpers/helpers";
import { ACTION_TYPES } from "./actions"
import { ACTION, STATE, VisualValidationBuildingState } from "./types";

const initialState: STATE = {
    searchResults: [],
    recentSearchResults: getFromLocalStorage('recentSearchResults', []),
    buildingId: null,
    buildingResult: null,
    floor: null,
    buildingLastUpdate: null,
    mappedToRevit: { rooms: [], chairs: [] },
    selectedFeature: {
        table: null,
        chair: null,
        room: null
    },
    visualValidationState: [],
    floorState: [],
    buildingState: VisualValidationBuildingState.NO_HARVEST,
    loading: false,
    error: null,
};

const reducer = (
    state: STATE = initialState,
    action: ACTION<any>
): STATE => {
    switch (action.type) {
        case ACTION_TYPES.SET_BUILDING_STATE:
            return {
                ...state,
                buildingState: action.payload.status
            }
        case ACTION_TYPES.SET_FLOOR_STATE:
            return {
                ...state,
                floorState: action.payload
            }
        case ACTION_TYPES.SET_SEARCH_RESULT:
            return {
                ...state,
                searchResults: action.payload
            }
        case ACTION_TYPES.SET_RECENT_SEARCH_RESULT:
            return {
                ...state,
                recentSearchResults: action.payload
            }
        case ACTION_TYPES.SET_BUILDING_RESULT:
            return {
                ...state,
                buildingResult: action.payload
            }
        case ACTION_TYPES.SET_FLOOR:
            return {
                ...state,
                floor: action.payload
            }
        case ACTION_TYPES.SET_BUILDING_ID:
            return {
                ...state,
                buildingId: action.payload
            }
        case ACTION_TYPES.SET_BUILDING_LAST_UPDATE:
            return {
                ...state,
                buildingLastUpdate: action.payload
            }
        case ACTION_TYPES.SET_MAPPED_TO_REVIT:
            return {
                ...state,
                mappedToRevit: action.payload
            }
        case ACTION_TYPES.SET_SELECTED_FEATURE:
            return {
                ...state,
                selectedFeature: action.payload
            }
        case ACTION_TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case ACTION_TYPES.SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
    }
    return state
}

export default reducer