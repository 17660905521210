import {FPObject, FPRoom} from "@wework/react-floor-plan-sdk";
import { FilterMetric } from "./components/HarvestReviewDiffSummaryMetrics";

interface MetricsByProgramType<T> {
    work: T, wash: T, circulate: T, we: T, meet: T, operate: T, other: T
}

interface SquareFootageMetrics {
    total: number,
    diffTotal: number,
    diff: MetricsByProgramType<number>,
    diffPercentage: MetricsByProgramType<number>,
    sum: MetricsByProgramType<number>,
    sumPrevious: MetricsByProgramType<number>
}

export interface HarvestDataItem {
    previous: FPRoom | undefined,
    current: FPRoom | undefined
}

export interface ChairsList {
    previousChairsWithInventory: FPObject[] | undefined,
    allCurrentChairs: FPObject[] | undefined
}

export class HarvestInfo {

    static getAllSpaceTypes = (harvestData: HarvestDataItem[]): string[] => {
        const items = new Set<string>()

        harvestData.forEach((harvestDataRow) => {
            const previousSpaceType = harvestDataRow.previous?.spaceType || harvestDataRow.previous?.designedAsSpaceType
            const currentSpaceType = harvestDataRow.current?.spaceType || harvestDataRow.current?.designedAsSpaceType
            if (previousSpaceType) items.add(previousSpaceType)
            if (currentSpaceType) items.add(currentSpaceType)
        })

        return Array.from(items)
    }

    static allProgramTypes = ['WE','WORK','MEET','CIRCULATE','WASH','OPERATE']

    static howManyRoomsRemoved = (harvestData: HarvestDataItem[]): number => {
        return harvestData.filter((item) =>  item.previous && !item.current).length
    }

    static howManyRoomsAdded = (harvestData: HarvestDataItem[]): number => {
        return harvestData.filter((item) => !item.previous && item.current).length
    }

    static howManyRoomsHaveDifferences = (harvestData: HarvestDataItem[]): number => {
        let sum = 0;
        harvestData.forEach((item) => {
            if (!HarvestInfo.isRoomTheSame(item)) {
                sum = sum + 1;
            }
        })

        return sum;
    }

    static howManyRoomsUnlinked = (harvestData: HarvestDataItem[]): number => {
        // count number of deleted rooms with inventory
        return  harvestData.filter((harvestDataRow) => {
            return !harvestDataRow.current &&
                harvestDataRow.previous?.inventoryId
        }).length
    }

    static howManyChairsUnlinked = (chairsList: ChairsList): number => {
        const previousChairsWithInventory = chairsList.previousChairsWithInventory?.filter(item => item.uuid && item.inventoryId) || []
        const previousChairsWithInventoryIds = new Set(previousChairsWithInventory.map(c => c.uuid))
        const currentChairsWithMatchingIds = chairsList.allCurrentChairs?.filter(c => previousChairsWithInventoryIds.has(c.uuid)) || []
        return (previousChairsWithInventory.length - currentChairsWithMatchingIds.length);
    }

    static howManyWorkUnitsHaveDifferences = (harvestData: HarvestDataItem[]): number => {
        // count number of deleted rooms with inventory
        return  harvestData.filter((harvestDataRow) => !HarvestInfo.isTheSameSpaceWorkUnits(harvestDataRow)).length
    }
    
    static getDiff = (previous: number, current: number): number => {
        return +Number(current - previous).toFixed(2)
    }

    static getPercentageChange = (previous: number, current: number): number => {
        if(previous > current) {
            return +Number(100 - (100 * current) / previous).toFixed(2) || 0
        } else if (previous < current) {
            return +Number(100 - (100 * previous) / current).toFixed(2) || 0
        } else {
            return 0
        }
    }

    static getWorkUnitsSum = (harvestData: HarvestDataItem[]): number => {
        let workUnits = 0;

        harvestData.forEach((harvestDataRow) => {
            const val = harvestDataRow.current?.metrics?.workUnits || 0;
            if (val) {
                workUnits = workUnits + val;
            }
        })

        return workUnits;
    }
    static getWorkUnitsDiff = (harvestData: HarvestDataItem[]): number => {
        let diff = 0;

        harvestData.forEach((harvestDataRow) => {
            const valPrevious = harvestDataRow.previous?.metrics?.workUnits || 0;
            const valCurrent = harvestDataRow.current?.metrics?.workUnits || 0;

            diff = diff + valCurrent - valPrevious;
        })

        return diff;
    }

    static getSquareFootageMetrics = (harvestData: HarvestDataItem[]): SquareFootageMetrics => {
        let sumPrevious = {
            work: 0, wash: 0, circulate: 0, we: 0, meet: 0, operate: 0, other: 0,
        }
        let sum = {
            work: 0, wash: 0, circulate: 0, we: 0, meet: 0, operate: 0, other: 0,
        }
        let total = 0;
        let diffTotal = 0

        harvestData.forEach((harvestDataRow) => {
            const programTypePrevious = harvestDataRow.previous?.programType || harvestDataRow.previous?.designedAsProgramType;
            const programTypeCurrent = harvestDataRow.current?.programType || harvestDataRow.current?.designedAsProgramType;
            const valPrevious = harvestDataRow.previous?.metrics?.squareFootage || 0;
            const valCurrent = harvestDataRow.current?.metrics?.squareFootage || 0;

            total = total + valCurrent;
            // total difference
            diffTotal = diffTotal + (valCurrent - valPrevious);

            const validProgramTypes = new Set(HarvestInfo.allProgramTypes)
            // difference by program type
            if (programTypePrevious && validProgramTypes.has(programTypePrevious)) {
                sumPrevious[programTypePrevious.toLowerCase()] += valPrevious
            } else {
                sumPrevious.other += valPrevious
            }
            if (programTypeCurrent && validProgramTypes.has(programTypeCurrent)) {
                sum[programTypeCurrent.toLowerCase()] += valCurrent
            } else {
                sum.other += valCurrent
            }
        })

        const diff = {
            work: sum.work && sumPrevious.work ? +Number(sum.work - sumPrevious.work).toFixed(2) : 0,
            wash: sum.wash && sumPrevious.wash ? +Number(sum.wash - sumPrevious.wash).toFixed(2) : 0,
            circulate: sum.circulate && sumPrevious.circulate ? +Number(sum.circulate - sumPrevious.circulate).toFixed(2) : 0,
            we: sum.we && sumPrevious.we ? +Number(sum.we - sumPrevious.we).toFixed(2) : 0,
            meet: sum.meet && sumPrevious.meet ? +Number(sum.meet - sumPrevious.meet).toFixed(2) : 0,
            operate: sum.operate && sumPrevious.operate ? +Number(sum.operate - sumPrevious.operate).toFixed(2) : 0,
            other: sum.other && sumPrevious.other ? +Number(sum.other - sumPrevious.other).toFixed(2) : 0,
        }

        const diffPercentage = {
            work: HarvestInfo.getPercentageChange(sumPrevious.work, sum.work), 
            wash: HarvestInfo.getPercentageChange(sumPrevious.wash, sum.wash), 
            circulate: HarvestInfo.getPercentageChange(sumPrevious.circulate, sum.circulate), 
            we: HarvestInfo.getPercentageChange(sumPrevious.we, sum.we),  
            meet: HarvestInfo.getPercentageChange(sumPrevious.meet, sum.meet),  
            operate: HarvestInfo.getPercentageChange(sumPrevious.operate, sum.operate), 
            other: HarvestInfo.getPercentageChange(sumPrevious.other, sum.other),
        }

        return {
            total: total || 0,
            diffTotal: diffTotal || 0,
            diff,
            diffPercentage,
            sum,
            sumPrevious,
        };
    }

    static isTheSameSpaceWorkUnits = (harvestDataRow: HarvestDataItem): boolean => {
        const previous = harvestDataRow.previous?.metrics?.workUnits
        const current = harvestDataRow.current?.metrics?.workUnits
        return (
            !!(previous || current)
            && (previous === current)
        ) || !!(!previous && !current)
    }

    static isTheSameSpaceType = (harvestDataRow: HarvestDataItem): boolean => {
        const previous = harvestDataRow.previous?.spaceType || harvestDataRow.previous?.designedAsSpaceType
        const current = harvestDataRow.current?.spaceType || harvestDataRow.current?.designedAsSpaceType
        return (
            !previous
            || (previous === current)
        ) || (!previous && !current)
    }

    static isTheSameSquareFootage = (harvestDataRow: HarvestDataItem): boolean => {
        return (
            !harvestDataRow.previous?.metrics?.squareFootage
            || (
                (Math.round((harvestDataRow.previous?.metrics?.squareFootage || 0) * 100) / 100)
                === (Math.round((harvestDataRow.current?.metrics?.squareFootage || 0) * 100) / 100)
            )
        )
    }

    static isTheSameMappedToInventory = (harvestDataRow: HarvestDataItem): boolean => {
        return (
            !harvestDataRow.previous?.inventoryId
            || (harvestDataRow.previous?.inventoryId === harvestDataRow.current?.inventoryId)
        )
    }

    static isTheSameHasWindow = (harvestDataRow: HarvestDataItem): boolean => {
        return (
            !harvestDataRow.previous?.hasWindow
            || (harvestDataRow.previous?.hasWindow === harvestDataRow.current?.hasWindow)
        )
    }

    static isTheSameProgramType = (harvestDataRow: HarvestDataItem): boolean => {
        const previous = harvestDataRow?.previous?.programType || harvestDataRow?.previous?.designedAsProgramType;
        const current = harvestDataRow?.current?.programType || harvestDataRow?.current?.designedAsProgramType;

        return previous === current;
    }

    static isTheSameWorkUnits = (harvestDataRow: HarvestDataItem): boolean => {
        return (
            harvestDataRow.previous?.metrics?.workUnits === harvestDataRow.current?.metrics?.workUnits
        )
    }

    static isTheSameInternalRoomCount = (harvestDataRow: HarvestDataItem): boolean => {
        return (
            !harvestDataRow.previous?.metrics?.internalRoomCount
            || (harvestDataRow.previous?.metrics?.internalRoomCount === harvestDataRow.current?.metrics?.internalRoomCount)
        )
    }

    static isRoomTheSame = (harvestDataRow: HarvestDataItem): boolean => {
        if (!HarvestInfo.isTheSameSpaceType(harvestDataRow)) return false
        if (!HarvestInfo.isTheSameSquareFootage(harvestDataRow)) return false
        if (!HarvestInfo.isTheSameMappedToInventory(harvestDataRow)) return false
        if (!HarvestInfo.isTheSameHasWindow(harvestDataRow)) return false
        if (!HarvestInfo.isTheSameProgramType(harvestDataRow)) return false
        if (!HarvestInfo.isTheSameWorkUnits(harvestDataRow)) return false
        return HarvestInfo.isTheSameInternalRoomCount(harvestDataRow);
    }
}

export const applyFilterMetric = (filterMetric: FilterMetric, item: HarvestDataItem) => { 
    if(filterMetric === FilterMetric.TOTAL_CHANGES) {
        return true
    }
    if(filterMetric === FilterMetric.ROOMS_CREATED) {
        return !item.previous?.uuid && item.current?.uuid
    }
    if(filterMetric === FilterMetric.ROOMS_DELETED) {
        return item.previous?.uuid && !item.current?.uuid
    }
    if(filterMetric === FilterMetric.ROOMS_UPDATED) {
        return !HarvestInfo.isRoomTheSame(item)
    }
    if(filterMetric === FilterMetric.UNLINKED_ROOM_INV) {
        return !HarvestInfo.isTheSameMappedToInventory(item)
    }
    if(filterMetric === FilterMetric.WORK_UNITS) {
        return !HarvestInfo.isTheSameSpaceWorkUnits(item)
    }
    else {
        return false
    }
}