/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {withRouter} from 'react-router-dom'
import {Dispatch} from "redux"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import {
    Annotation,
    FloorChildren,
    FloorPlanBaseMapStyle,
    FloorPlanCamera,
    FloorPlanConfig,
    FloorPlanData,
    FloorPlanEvent,
    FloorPlanLoadEvent,
    FPObject,
    FPRoom,
    IPoint,
    PolygonAnnotation,
    PolygonAnnotationStyle,
    ReactFloorPlan,
} from "@wework/react-floor-plan-sdk";
import {useAuth0} from '@auth0/auth0-react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Floor, STATE, VisualValidationBuildingState} from "../../store/types";
import {CHAIR_STYLES, ERROR_SLACK_CHANNEL, FLOOR_PLAN_ENVIRONMENT, TABLE_STYLES} from '../../constants';
import {
    cleanBuildingResult,
    fetchBuildingLastUpdate,
    fetchBuildingResult, loadBuildingState,
    setBuildingId,
    setBuildingState,
    setError,
    setFloor,
    setLoading,
} from '../../store/actions';
import Modal from '../../components/Modal/Modal';
import HarvestReviewTable from './components/HarvestReviewTable'

import FloorErrorEmpty from '../../components/floorErrorEmpty/FloorErrorEmpty';
import FloorErrorLoading from '../../components/floorErrorLoading/FloorErrorLoading';

import HarvestReviewDiffSummary from './components/HarvestReviewDiffSummary';
import {ChairsList, HarvestDataItem} from "./helpers";
import Loader from "../../components/Loader/Loader";
import {getFormattedDate} from "../../helpers/helpers";
import HarvestReviewDiffSummaryMetrics, {FilterMetric} from "./components/HarvestReviewDiffSummaryMetrics";

import './HarvestReview.css';
import HarvestReviewDiffChart from "./components/HarvestReviewDiffChart";

const shapeWeWorkIcon = require('../../assets/images/shapeWeWork.svg');

const DEFAULT_ZOOM = 21;
const floorPlanChildren: FloorChildren[] = [FloorChildren.FLOOR, FloorChildren.OBJECTS, FloorChildren.ROOMS, FloorChildren.WALLS, FloorChildren.DOORS]

export enum VIEW_TYPE {
    ALL = 'ALL',
    ROOM = 'ROOM',
    CHAIR = 'CHAIR'
}

function HarvestReview({ history, match }: any) {
    const currentRoom = new URLSearchParams(window.location.search).get('selected');

    const [annotationsPrevious, setAnnotationsPrevious] = useState<Annotation[]>([])
    const [annotationsCurrent, setAnnotationsCurrent] = useState<Annotation[]>([])
    const [token, setToken] = useState("")
    const [isErrorLoading, setIsErrorLoading] = useState(false)
    const [isFloorEmpty, setIsFloorEmpty] = useState(false)
    const [retryTimes, setRetryTimes] = useState(+(localStorage.getItem('retry') || 0))
    const [zoomToPrevious, setZoomToPrevious] = useState<{ point: IPoint, animated: boolean, maxZoomLevel: number } | null>(null)
    const [zoomToCurrent, setZoomToCurrent] = useState<{ point: IPoint, animated: boolean, maxZoomLevel: number } | null>(null)
    const [selectedIds, setSelectedIds] = useState<string[]>(currentRoom ? [currentRoom] : [])
    const [isMirroringDisable, setIsMirroringDisable] = useState(false) // TODO: do we need Mirroring off?
    const [filterMetric, setFilterMetric] = useState<FilterMetric>(FilterMetric.TOTAL_CHANGES)
    const [hideFurniture, setHideFurniture] = useState(false)
    const [objectsPrevious, setObjectsPrevious] = useState<FPObject[] | null>(null)
    const [objectsCurrent, setObjectsCurrent] = useState<FPObject[] | null>(null)
    const [showMetricsSummary, setShowMetricsSummary] = useState(false)
    const [viewType, setViewType] = useState(VIEW_TYPE.ALL)
    const [chairsList, setChairsList] = useState<ChairsList>({} as ChairsList)

    const isOnPrevious = useRef(false)
    const isOnCurrent = useRef(false)
    const harvestReviewMapPrevious = useRef<any>(null)
    const harvestReviewMapCurrent = useRef<any>(null)

    const dispatch: Dispatch<any> = useDispatch()
    const _getBuildingResult = React.useCallback((id: string, token: string) => dispatch(fetchBuildingResult(id, token)), [dispatch]);
    const _setFloor = React.useCallback((data: Floor) => dispatch(setFloor(data)), [dispatch]);
    const _setBuildingId = React.useCallback((value: string) => dispatch(setBuildingId(value)), [dispatch])
    const _cleanError = React.useCallback(() => dispatch(setError(null)), [dispatch])
    const _setLoading = React.useCallback((loading: boolean) => dispatch(setLoading(loading)), [dispatch]);
    const _getBuildingLastUpdate = React.useCallback((id: string, token: string) => dispatch(fetchBuildingLastUpdate(id, token)), [dispatch]);
    const _cleanBuildingResult = React.useCallback(() => dispatch(cleanBuildingResult()), [dispatch]);
    const buildingState: VisualValidationBuildingState = useSelector((state: STATE) => state.buildingState, shallowEqual);
    const _loadBuildingState = React.useCallback((buildingId: string, token: string) => dispatch(
        loadBuildingState(buildingId, token)), [dispatch]);
    const _setBuildingState = React.useCallback((buildingId: string, status: VisualValidationBuildingState, token: string) => dispatch(
        setBuildingState(buildingId, status, token)), [dispatch]);

    const building = useSelector((state: STATE) => state.buildingResult, shallowEqual);
    const buildingLastUpdate = useSelector((state: STATE) => state.buildingLastUpdate, shallowEqual);
    const floor = useSelector((state: STATE) => state.floor, shallowEqual);
    const buildingId = useSelector((state: STATE) => state.buildingId, shallowEqual);
    const [floorPlanData, setFloorPlanData] = useState<{ previous?: FloorPlanData, current?: FloorPlanData }>({})
    const [roomsHarvestData, setRoomsHarvestData] = useState(new Array<HarvestDataItem>())
    const error = useSelector((state: STATE) => state.error, shallowEqual);
    const loading = useSelector((state: STATE) => state.loading, shallowEqual);

    const { getAccessTokenSilently, user } = useAuth0();

    const configPrevious: FloorPlanConfig = {
        environment: FLOOR_PLAN_ENVIRONMENT,
        auth0TokenProvider: getAccessTokenSilently,
        includeInventoryData: true
    }
    const configCurrent: FloorPlanConfig = {
        environment: FLOOR_PLAN_ENVIRONMENT,
        auth0TokenProvider: getAccessTokenSilently,
        displayUnapprovedLatestHarvestData: true,
        includeInventoryData: true
    }

    const convertFPObjectsToPolygonAnnotation = (objects: FPObject[]): PolygonAnnotation[] => {
        return objects.map((object) => {
            const id = object.uuid as string;
            const type = object.objectType
            let newStyle: PolygonAnnotationStyle
            if (type === 'CHAIR') {
                if (
                    object.inventoryId 
                    && (viewType === VIEW_TYPE.CHAIR || viewType === VIEW_TYPE.ALL)
                ) {
                    newStyle = CHAIR_STYLES.MAPPED
                } else {
                    newStyle = CHAIR_STYLES.WITHOUT_INVENTORY
                }
            } else {
                newStyle = object.inventoryId ? TABLE_STYLES : CHAIR_STYLES.WITHOUT_INVENTORY
            }

            // newStyle = DISABLED_STYLE

            const annotation: PolygonAnnotation = {
                id,
                type: 'Feature' as const,
                geometry: {
                    type: 'Polygon' as const,
                    coordinates: [object.projectedBoundariesInFeet?.outerBoundary?.pointsList.map(p => [p.x, p.y]) || []]
                        .concat(
                            object.projectedBoundariesInFeet?.innerVoidBoundariesList?.map((boundary) =>
                                boundary.pointsList.map(p => [p.x, p.y]) || []
                            ) || []
                        ),
                },
                properties: {
                    type,
                    inventoryId: object.inventoryId,
                    deskNumber: object.objectIdentifier,
                    label: '',
                    ...newStyle
                }
            }

            if (object.objectType === "CHAIR" && object.inventoryId && object.objectIdentifier.length) {
                annotation.properties.label = object.objectIdentifier
            }

            return annotation
        })
            .sort((a) => {
                if (a.properties.type === 'CHAIR') return 1
                return -1
            })
    }

    const onFloorWillLoad = useCallback(() => _setLoading(true), [_setLoading])

    const onFloorLoad = useCallback((event: FloorPlanLoadEvent, isPrevious: boolean) => {
        if (isPrevious) setAnnotationsPrevious([])
        else setAnnotationsCurrent([])
        _setLoading(false)
        if (event.floorPlan) {
            if (isPrevious && setObjectsPrevious) setObjectsPrevious(event.floorPlan.objects)
            if (!isPrevious && setObjectsCurrent) setObjectsCurrent(event.floorPlan.objects)

            setIsErrorLoading(false)

            const isEmpty = !event.floorPlan.rooms?.length &&
                !event.floorPlan.doors?.length &&
                !event.floorPlan.objects?.length &&
                !event.floorPlan.portals?.length &&
                !event.floorPlan.walls?.length
            setIsFloorEmpty(isEmpty)

            const annotations = convertFPObjectsToPolygonAnnotation(event.floorPlan.objects)
    
            if (isPrevious && setAnnotationsPrevious) setAnnotationsPrevious(annotations)
            if (!isPrevious && setAnnotationsCurrent) setAnnotationsCurrent(annotations)


            // add map floor plan to local state for display in table
            if (isPrevious) {
                const _chairsWithInventory = (event.floorPlan as FloorPlanData)?.objects?.filter((object) => object.inventoryId)
                setChairsList((value) => ({...value, previousChairsWithInventory: _chairsWithInventory || []}))
                setFloorPlanData((value) => ({ ...value, previous: event.floorPlan as FloorPlanData }))
            } else {
                const _chairs = (event.floorPlan as FloorPlanData)?.objects?.filter((object) => object.objectType === 'CHAIR')
                setChairsList((value) => ({...value, allCurrentChairs: _chairs || []}))
                setFloorPlanData((value) => ({ ...value, current: event.floorPlan as FloorPlanData }))
            }
        } else {
            setIsErrorLoading(true)
            setFloorPlanData({})
        }
    }, [setAnnotationsPrevious, setAnnotationsCurrent, setIsErrorLoading, setIsFloorEmpty, setFloorPlanData, _setLoading])

    const onCurrentFloorLoad = useCallback(
        (event: FloorPlanLoadEvent) => onFloorLoad(event, false),
        [onFloorLoad]
    )

    const onPreviousFloorLoad = useCallback(
        (event: FloorPlanLoadEvent) => onFloorLoad(event, true),
        [onFloorLoad]
    )

    const onZoomToCurrent = useCallback((e: FloorPlanCamera) => {
        if (
            !isMirroringDisable
            && isOnCurrent.current
            && (
                e.center.x !== zoomToCurrent?.point.x
                || e.center.y !== zoomToCurrent?.point.y
                || e.zoom !== zoomToCurrent?.maxZoomLevel
            )
        ) {
            setZoomToPrevious(null);
            setZoomToCurrent({
                point: e.center,
                animated: false,
                maxZoomLevel: e.zoom || DEFAULT_ZOOM
            })
        }
    }, [isMirroringDisable])

    const onZoomToPrevious = useCallback((e: FloorPlanCamera) => {
        if (
            !isMirroringDisable
            && isOnPrevious.current
            && (
                e.center.x !== zoomToPrevious?.point.x
                || e.center.y !== zoomToPrevious?.point.y
                || e.zoom !== zoomToPrevious?.maxZoomLevel
            )
        ) {
            setZoomToCurrent(null);
            setZoomToPrevious({
                point: e.center,
                animated: false,
                maxZoomLevel: e.zoom || DEFAULT_ZOOM
            })
        }
    }, [isMirroringDisable])

    const onClickMap = (e: FloorPlanEvent) => {
        let id;

        const room = e.features.rooms[0]
        const chair = e.features.annotations.find(annotation => annotation.properties.type === 'CHAIR')

        if(viewType === VIEW_TYPE.CHAIR) {
            id = chair?.id
        } else if(viewType === VIEW_TYPE.ALL) {
            id = chair?.id || room.uuid
        } else {
            id = room.uuid
        }

        if (id && e.point && id !== selectedIds[0]) {
            setSelectedIds([id])
            setZoomToCurrent({ point: e.point, animated: true, maxZoomLevel: DEFAULT_ZOOM })
            setZoomToPrevious({ point: e.point, animated: true, maxZoomLevel: DEFAULT_ZOOM })

            const el = document.getElementById(id)
            el?.scrollIntoView({ behavior: "smooth" })
        } else {
            setSelectedIds([])
        }
    }

    const onClickTableRow = (uuid: string, centroidInFeet: { x: number, y: number } | undefined) => {
        if(selectedIds[0] === uuid) {
            setSelectedIds([])
            history.push({
                pathname: '/harvest-review/' + buildingId + '/' + floor?.id,
            })
            return
        }

        if (centroidInFeet) {
            setZoomToPrevious(_prev => ({
                point: { x: centroidInFeet.x, y: centroidInFeet.y },
                animated: true,
                maxZoomLevel: /* prev?.maxZoomLevel || */ DEFAULT_ZOOM
            }))
            setZoomToCurrent(_prev => ({
                point: { x: centroidInFeet.x, y: centroidInFeet.y },
                animated: true,
                maxZoomLevel: /* prev?.maxZoomLevel || */ DEFAULT_ZOOM
            }))
            setSelectedIds([uuid])

            history.push({
                pathname: '/harvest-review/' + buildingId + '/' + floor?.id,
                search: `?selected=${uuid || ''}`
            })
        }
    }

    function mousemoveMapPrevious() {
        isOnCurrent.current = false;
        isOnPrevious.current = true;
    }

    function mousemoveMapCurrent() {
        isOnPrevious.current = false;
        isOnCurrent.current = true;
    }

    useEffect(() => {
        if(objectsPrevious) {
            let annotations = convertFPObjectsToPolygonAnnotation(objectsPrevious)
            if(hideFurniture) {
                annotations = annotations.filter(annotation => annotation.properties?.type !== 'CHAIR')
                setAnnotationsPrevious(annotations)
            } else {
                setAnnotationsPrevious(annotations)
            }
        }
        if(objectsCurrent) {
            let annotations = convertFPObjectsToPolygonAnnotation(objectsCurrent)
            if(hideFurniture) {
                annotations = annotations.filter(annotation => annotation.properties?.type !== 'CHAIR')
                setAnnotationsCurrent(annotations)
            } else {
                setAnnotationsCurrent(annotations)
            }
        }
    },[hideFurniture, viewType])

    useEffect(() => {
        harvestReviewMapPrevious.current
            ?.addEventListener("mousemove", mousemoveMapPrevious);
        harvestReviewMapCurrent.current
            ?.addEventListener("mousemove", mousemoveMapCurrent);

        return () => {
            harvestReviewMapPrevious.current?.removeEventListener("mousemove", mousemoveMapPrevious)
            harvestReviewMapPrevious.current?.removeEventListener("mousemove", mousemoveMapCurrent)
        };
    }, [harvestReviewMapPrevious.current, harvestReviewMapCurrent.current])

    const handleClick = (e: any) => {
        const list = document.getElementById("harvest-review-list")
        const maps = document.getElementById("harvest-review-maps")

        if (
            !list?.contains(e.target)
            && !maps?.contains(e.target)
        ) {
            setSelectedIds([])
        }
    }

    useEffect(() => {
        _setBuildingId(match?.params?.building)

        window.addEventListener("click", handleClick, true);
        return () => {
            window.removeEventListener("click", handleClick, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            const retry = +(localStorage.getItem('retry') || 0) + 1
            localStorage.setItem('retry', '' + retry)
            setRetryTimes(retry)
            setIsErrorLoading(true)
            _cleanError()
        } else {
            _cleanError()
        }
    }, [error])

    useEffect(() => {
        if (!buildingId) return;
        getAccessTokenSilently().then((_token) => {
            setToken(_token)
            if (building?.id !== buildingId) _getBuildingResult(buildingId, _token)
            _getBuildingLastUpdate(buildingId, _token)
            _loadBuildingState(buildingId, _token)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingId])

    useEffect(() => {
        if (!building?.floors?.length) return;

        const _floor = building?.floors.filter((floor) => floor.id === match?.params?.floor)[0] || building?.floors[0];
        _setFloor(_floor)

        history.push({
            pathname: '/harvest-review/' + buildingId + '/' + _floor?.id,
            search:    selectedIds[0] ? `?selected=${selectedIds[0]}` : ''
        })
    }, [building, selectedIds])

    // observe floor plan data loaded by both maps and create previous/current room matches after load completion.
    useEffect(() => {
        if (floorPlanData.previous && floorPlanData.current) {
            // index by uuid for efficient lookups
            const previousById = new Map<String, FPRoom>(floorPlanData.previous.rooms.map(a => [a.uuid, a]))
            const currentById = new Map<String, FPRoom>(floorPlanData.current.rooms.map(a => [a.uuid, a]))

            // add all previous rooms, matching with current rooms (by uuid) if present.
            let roomMatches = floorPlanData.previous.rooms.map((room) => {
                const match = currentById.get(room.uuid)
                // inventory mapping is by uuid. These two rooms share a uuid, thus we can apply the existing inventory mapping to the new room.
                if (match) match.inventoryId = room.inventoryId
                return { previous: room, current: match } as HarvestDataItem
            })

            // add all new rooms (rooms not present in previous harvest)
            roomMatches = roomMatches.concat(
                floorPlanData.current.rooms
                    .filter(newRoom => !previousById.has(newRoom.uuid))
                    .map(newRoom => {
                        return { previous: undefined, current: newRoom } as HarvestDataItem
                    })
            )

            setRoomsHarvestData(roomMatches)
        } else {
            setRoomsHarvestData([])
        }
    }, [floorPlanData, setRoomsHarvestData])

    const isBusinessReviewApproved = buildingState === VisualValidationBuildingState.BUSINESS_REVIEW_APPROVED

    return (
        <>
            <div className="container harvest-review-container">
                <div className="harvest-review-header">
                    <div className="harvest-review-header-top">

                        <div className="header-logo"
                            onClick={() => { 
                                _cleanBuildingResult();
                                history.push('/search');
                            }}
                        >
                            <img src={shapeWeWorkIcon.default} alt="" className="inventory-summary-item-icon" />
                        </div>

                        <span
                            className="harvest-review-header-title-building"
                        >
                            {
                                `${building?.name || ""}`
                            }
                        </span>

                        <div className={
                            floor
                                ? "ray-select ray-select--compact ray-select--has-value harvest-review-top-select"
                                : "ray-select ray-select--compact harvest-review-top-select"
                        }>
                            <select
                                className="ray-select__input"
                                id="floor"
                                name="floor"
                                value={floor?.label}
                                onChange={(e) => {
                                    const val = e.target.value
                                    const _floor = building?.floors.filter(_floor => _floor.label === val)[0]
                                    if (_floor) {
                                        setSelectedIds([])
                                        _cleanError()
                                        _setFloor(_floor);
                                        history.push({
                                            pathname: '/harvest-review/' + buildingId + '/' + _floor?.id,
                                            search:    currentRoom ? `?room=${currentRoom}` : ''
                                        })
                                    }
                                }}
                            >
                                {
                                    building?.floors
                                        .map((_floor) => {
                                            const label = _floor.hasChairs ? _floor.label + ' 🪑' : _floor.label
                                            return <option
                                                className={floor?.label === _floor.label ? 'selected' : ''}
                                                key={_floor.label}
                                                value={_floor.label}
                                            >{label}</option>
                                        })
                                }
                            </select>
                            <label className="ray-select__label small-text harvest-review-select-label" htmlFor="floor">
                                Floor
                            </label>
                        </div>

                        <span className="harvest-review-header-title-created">
                                {
                                    `Last update ${getFormattedDate(buildingLastUpdate?.updatedAt || null, 'MMM DD YYYY')}`
                                }
                        </span>
                        <div className="toggle-border">
                            <input 
                                className={
                                    viewType === VIEW_TYPE.ALL ? "floor-plan-btn toggle-left" : "floor-plan-btn secondary toggle-left"
                                } 
                                type="button" 
                                value="All"
                                onClick={() => {
                                    setHideFurniture(false)
                                    setViewType(VIEW_TYPE.ALL)
                                }} 
                            />
                            <input 
                                className={
                                    viewType === VIEW_TYPE.ROOM ? "floor-plan-btn toggle-left" : "floor-plan-btn secondary toggle-left"
                                } 
                                type="button" 
                                value="Room"
                                onClick={() => {
                                    setViewType(VIEW_TYPE.ROOM)
                                    if(filterMetric === FilterMetric.UNLINKED_CHAIR_INV) {
                                        setFilterMetric(FilterMetric.TOTAL_CHANGES)
                                    }
                                }}
                            />
                            <input 
                                className={
                                    viewType === VIEW_TYPE.CHAIR ? "floor-plan-btn toggle-left" : "floor-plan-btn secondary toggle-left" 
                                }
                                type="button"
                                value="Chair"
                                onClick={() => {
                                    setHideFurniture(false)
                                    setViewType(VIEW_TYPE.CHAIR)
                                    if(filterMetric !== FilterMetric.UNLINKED_CHAIR_INV) {
                                        setFilterMetric(FilterMetric.TOTAL_CHANGES)
                                    }
                                }} 
                            />
                        </div>
                        { 
                            (objectsPrevious || objectsCurrent) 
                            && (viewType !== VIEW_TYPE.ALL && viewType !== VIEW_TYPE.CHAIR) && (
                                <div className="hide-furniture-harvest ray-checkbox">
                                    <input
                                        id="checkbox-1"
                                        name="checkbox-button-story"
                                        type="checkbox"
                                        className="ray-checkbox__input"
                                        defaultChecked={hideFurniture}
                                        onClick={() => {
                                            setHideFurniture(prev => !prev)
                                        }}
                                    />
                                    <label className="ray-checkbox__label" htmlFor="checkbox-1">
                                        Hide furniture
                                    </label>
                                </div>
                            )
                        }
                        {
                            showMetricsSummary && (
                                <HarvestReviewDiffSummary
                                    harvestData={roomsHarvestData}
                                />
                            )
                        }

                        {/* only show this button to people with wework email addresses */}
                        { (user?.email ||'').endsWith('@wework.com')  && (
                            <div className="align-right">
                                <input
                                    className={
                                        isBusinessReviewApproved ?  "floor-plan-btn secondary toggle-left" : "floor-plan-btn toggle-left"
                                    }
                                    type="button"
                                    disabled={isBusinessReviewApproved}
                                    value={isBusinessReviewApproved ? "Business Review Already Approved" : "Approve Business Review"}
                                    onClick={() => {
                                        if (!isBusinessReviewApproved)
                                            _setBuildingState(building?.id || '',
                                                VisualValidationBuildingState.BUSINESS_REVIEW_APPROVED,
                                                token)
                                    }}
                                />
                            </div>
                        )}


                    </div>

                    <div className="harvest-review-diff">
                        <HarvestReviewDiffSummaryMetrics
                            active={filterMetric}
                            onClick={(item) => { 
                                setFilterMetric(item)
                                if(
                                    item === FilterMetric.TOTAL_CHANGES
                                ) {
                                    setHideFurniture(false)
                                    setViewType(VIEW_TYPE.ALL)
                                } else if(
                                    item === FilterMetric.UNLINKED_CHAIR_INV 
                                    && viewType === VIEW_TYPE.ROOM
                                ) {
                                    setHideFurniture(false)
                                    setViewType(VIEW_TYPE.CHAIR)
                                } else if(
                                    item !== FilterMetric.UNLINKED_CHAIR_INV
                                ) {
                                    setViewType(VIEW_TYPE.ROOM)
                                }
                            }}
                            harvestData={roomsHarvestData}
                            chairsList={chairsList}
                        />
                        <div className="harvest-review-diff-chart-wrapper"
                            onMouseEnter={() => setShowMetricsSummary(true)}
                            onMouseLeave={() => setShowMetricsSummary(false)}
                        >
                            <HarvestReviewDiffChart
                                harvestData={roomsHarvestData}
                            />
                        </div>
                    </div>
                </div>
            </div >

            <div className="harvest-review-main">
                <div className="harvest-review--main-info-section">
                    <HarvestReviewTable 
                        viewType={viewType}
                        chairsList={chairsList}
                        harvestData={roomsHarvestData}
                        selectedId={selectedIds[0]}
                        filterMetric={filterMetric}
                        onClickRow={onClickTableRow} 
                    />
                </div>
                <div className="harvest-review--main-maps-section"
                    id="harvest-review-maps"
                >
                        {
                            building ? (
                                <div className="harvest-review-map"
                                    id="harvest-review-map-current"
                                    ref={harvestReviewMapCurrent}
                                >
                                    <ReactFloorPlan
                                        config={configCurrent}
                                        mapStyle={FloorPlanBaseMapStyle.REVIT}
                                        floorId={floor?.id || ""}
                                        children={floorPlanChildren}
                                        annotations={(
                                            hideFurniture 
                                            && (viewType !== VIEW_TYPE.CHAIR && viewType !== VIEW_TYPE.ALL)
                                        ) ? [] : annotationsCurrent}
                                        roomHoverEnabled={viewType === VIEW_TYPE.ALL || viewType === VIEW_TYPE.ROOM}
                                        selectedIds={selectedIds}
                                        zoomToPoint={zoomToPrevious}
                                        onWillLoad={onFloorWillLoad}
                                        onLoad={onCurrentFloorLoad}
                                        onCameraChanged={onZoomToCurrent}
                                        onClick={onClickMap}
                                    />
                                    {
                                        <span className="harvest-review-map-label">
                                            New
                                        </span>
                                    }
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        <div className="harvest-review-map-br"></div>
                        {
                            building ? (
                                <div className="harvest-review-map"
                                    id="harvest-review-map-previous"
                                    ref={harvestReviewMapPrevious}
                                >
                                    <ReactFloorPlan
                                        config={configPrevious}
                                        mapStyle={FloorPlanBaseMapStyle.REVIT}
                                        floorId={floor?.id || ""}
                                        children={floorPlanChildren}
                                        annotations={(
                                            hideFurniture 
                                            && (viewType !== VIEW_TYPE.CHAIR && viewType !== VIEW_TYPE.ALL)
                                        ) ? [] : annotationsPrevious}
                                        roomHoverEnabled={viewType === VIEW_TYPE.ALL || viewType === VIEW_TYPE.ROOM}
                                        selectedIds={selectedIds}
                                        zoomToPoint={zoomToCurrent}
                                        onLoad={onPreviousFloorLoad}
                                        onCameraChanged={onZoomToPrevious}
                                        onClick={onClickMap}
                                    />
                                    {
                                        <span className="harvest-review-map-label">
                                            Previous
                                        </span>
                                    }
                                </div>
                            ) : (
                                <></>
                            )
                        }
                </div>
            </div>

            <FloorErrorEmpty
                show={isFloorEmpty}
            />
            <FloorErrorLoading
                show={isErrorLoading}
                retryTimes={retryTimes}
            />

            <Modal
                show={
                    error?.type === 'status-floor-error'
                }
                onClose={() => { _cleanError() }}
            >
                <div className="modal-submin-title">
                    Whoops! We're having some network issues.
                </div>
                <div className="text-small">
                    Try reloading the page or <span> </span>
                    <a href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                        report this issue
                    </a>
                </div >   
            </Modal>
            <Modal
                show={
                    error?.type === 'status-building-error'
                }
                onClose={() => { _cleanError() }}
            >
                <div className="modal-submin-title">
                    Whoops! We're having some network issues.
                </div>
                <div className="text-small">
                    Try resubmitting or <span> </span>
                    <a href={ERROR_SLACK_CHANNEL} target="_blank" rel="noreferrer">
                        report this issue
                    </a>
                </div>
            </Modal>

            <Loader
                show={loading}
            />
        </>
    );
}

export default withRouter(HarvestReview);
