import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from "redux-thunk"
import { createLogger } from 'redux-logger'
import reducer from "./reducer"
import { ACTION, DispatchType, STATE } from "./types"


const logger = createLogger({
  collapsed: true
});

const middleware = [thunk, logger];

const composeEnhancers =
  typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const STORE: Store<STATE, ACTION<any>> & {
  dispatch: DispatchType<any>
} = createStore(reducer, composeEnhancers(
  applyMiddleware(...middleware)
));

export default STORE;