
import React from "react";

import './Input.css';

const Input = React.memo(function ({
    isValidStyle = false, 
    value,
    onChange,
    className = "",
    label = '',
    placeholder='',
    children = <></>
}: any) {
    return (
        <div id='input-container'
            className={className ? "input-container " + className : ""}>
            <span className={!value && !isValidStyle? "input-label" : "input-label valid"}>
                {label}
            </span>
            <input className="input" type="search"
                placeholder={placeholder}
                value={value || ""}
                required
                onChange={(event) => {
                    const val = event.target.value;
                    onChange(val)
                }}
            />

            {children}
        </div>

    );
})

export default Input;