import React, { useEffect, useState } from 'react';
import { Dispatch } from "redux"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { copyToClipboard, getFormattedText } from '../../helpers/helpers';
import { MappedToRevit, SelectedFeature, STATE } from '../../store/types';
import { setMappedToRevit, setSelectedFeature } from '../../store/actions';
import lodash from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import './InfoSection.css';
import { exportRowsForRooms } from "../../helpers/RoomScheduleExport";
import { FPObject, FPRoom } from "@wework/react-floor-plan-sdk";
import { getObjectName } from "../../pages/Building/helpers";

const closeIcon = require('../../assets/images/close.svg');

type InfoSectionState = {
    showHasInventory?: boolean,
    showCopyEmail?: boolean,
    onChange?: () => void,
}
const InfoSection = React.memo(function ({
    onChange = () => { },
    showHasInventory = true,
    showCopyEmail = true,
}: InfoSectionState) {
    const [selected, setSelected] = useState(false);
    const [id, setId] = useState('');
    const [coppied, setCoppied] = useState(false);
    //
    const dispatch: Dispatch<any> = useDispatch();
    const _setMappedToRevit = React.useCallback((data: MappedToRevit) => dispatch(setMappedToRevit(data)), [dispatch])
    const _setSelectedFeature = React.useCallback((data: SelectedFeature) => dispatch(setSelectedFeature(data)), [dispatch])

    const mappedToRevit: MappedToRevit = useSelector((state: STATE) => state.mappedToRevit, shallowEqual);
    const selectedFeature: SelectedFeature = useSelector((state: STATE) => state.selectedFeature, shallowEqual)

    useEffect(() => {
        const _id = selectedFeature.chair ? selectedFeature.chair?.uuid : selectedFeature.room?.room?.uuid;
        if (!_id) return;

        setId(_id)

        setSelected(
            mappedToRevit?.rooms.includes(_id)
            || mappedToRevit?.chairs.includes(_id)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFeature]);

    const _onClose = () => {
        _setSelectedFeature({ table: null, chair: null, room: null })
    }

    const _onChange = (id: string, _selected: boolean) => {
        setSelected(_selected)
        if (!_selected) {
            _setMappedToRevit({
                rooms: !selectedFeature.chair ? mappedToRevit.rooms?.filter((item) => item !== id) : mappedToRevit.rooms,
                chairs: selectedFeature.chair ? mappedToRevit.chairs?.filter((item) => item !== id) : mappedToRevit.chairs,
            })
        } else {
            _setMappedToRevit({
                rooms: !selectedFeature.chair ? lodash.union([...mappedToRevit.rooms, id]) : mappedToRevit.rooms,
                chairs: selectedFeature.chair ? lodash.union([...mappedToRevit.chairs, id]) : mappedToRevit.chairs,
            })
        }

        // clear app selection and hide info panel
        _setSelectedFeature({ chair: null, room: null, table: null })

        onChange();
    }

    const _onCopyChair = () => {
        const chair = selectedFeature.chair
        if (chair) {
            copyToClipboard(JSON.stringify({ "WW-ChairNumber": getObjectName(chair), "SRS_UUID": chair.uuid }))
        }
        setCoppied(true);
        setTimeout(() => {
            setCoppied(false);
        }, 3000);
    }

    const _onCopyRoom = () => {
        const row = exportRowsForRooms(selectedFeature.room!.rooms)
            .find((r) => r.SRS_UUID === selectedFeature.room!.room.uuid)
        if (row) {
            copyToClipboard(JSON.stringify(row))
        }
        setCoppied(true);
        setTimeout(() => {
            setCoppied(false);
        }, 3000);
    }

    const getMappedToRevitRecordModel = () => {
        return (
            <div className="mapped-to-revit-record-model">
                <div className="info-section-row-mapped">
                    <input
                        className={
                            selected
                                ? "floor-plan-btn grey info-section-btn text-small"
                                : "floor-plan-btn info-section-btn text-small"
                        }
                        type="button"
                        value={selected ? "Undo" : "Done"}
                        onClick={() => {
                            _onChange(id, !selected);
                            setSelected(!selected);
                        }}
                    />
                </div>
            </div>
        )
    }

    const getHasInventory = (feature: FPObject | FPRoom | undefined) => {
        return (
            <div className={
                showHasInventory
                    ? "info-section-row-header"
                    : 'display-none'
            }>
                <div className="info-section-row-left room-number text-big">
                    {getObjectName(selectedFeature.chair) || selectedFeature.room?.room?.roomNumber || ""}
                </div>
                <div className={feature?.inventoryId ? "info-section-row inventory has-inventory" : "display-none"}>
                    HAS INVENTORY
                </div>
                <div className={!feature?.inventoryId ? "info-section-row inventory no-inventory" : "display-none"}>
                    NO INVENTORY
                </div>
            </div>
        )
    }

    return (
        <div className="info-section">
            <span
                className="info-section-close"
                onClick={_onClose}
            >
                <img src={closeIcon.default} alt="X" className="close-icon" />
            </span>
            {
                selectedFeature.chair
                    ? (
                        <>
                            {
                                getHasInventory(selectedFeature.chair)
                            }
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Type:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        getFormattedText(selectedFeature.chair?.objectType)
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Centroid (x, y):
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        selectedFeature.chair?.centroidInFeet?.x
                                        + ", "
                                        + selectedFeature.chair?.centroidInFeet?.y
                                    }
                                </div>
                            </div>
                            <div className={showCopyEmail ? "mapped-to-revit-record-model" : 'display-none'}>
                                <div className={
                                    coppied ? "copied-to-clipboard" : 'display-none'
                                }>
                                    <span className="copied-to-clipboard-item text-small"> Copied to clipboard </span>
                                </div>
                                <div className={selected ? "info-section-row-copy coppied" : "info-section-row-copy"}
                                    onClick={_onCopyChair}
                                >
                                    <div
                                        className={selected ? "info-section-id-title coppied text-small" : "info-section-id-title text-small"}>
                                        Properties
                                    </div>
                                    <div
                                        className={selected ? "info-section-id coppied text-small" : "info-section-id text-small"}>
                                        {`Chair Number: ${getObjectName(selectedFeature.chair)}`}
                                    </div>
                                    <input className="floor-plan-btn info-section-btn text-small" type="button"
                                        value="Copy JSON"
                                    />
                                </div>
                            </div>
                            {showCopyEmail && getMappedToRevitRecordModel()}
                        </>
                    ) : (
                        <>
                            {getHasInventory(selectedFeature.room?.room)}
                            <p className="info-section-row-office">
                                Room
                            </p>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Space Type:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        getFormattedText(selectedFeature.room?.room?.designedAsSpaceType || "")
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Program Type:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        getFormattedText(selectedFeature.room?.room?.designedAsProgramType || "")
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Square footage:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        Math.round((selectedFeature?.room?.room?.metrics?.squareFootage || 0) * 100) / 100
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Centroid (x, y):
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        selectedFeature.room?.room?.centroidInFeet?.x
                                        + ", "
                                        + selectedFeature.room?.room?.centroidInFeet?.y
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Seat count:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        selectedFeature.room?.room?.metrics?.seatCount
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Desk count:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {
                                        selectedFeature.room?.room?.metrics?.deskCount
                                    }
                                </div>
                            </div>
                            <div className="info-section-row">
                                <div className="info-section-row-title info-section-row-item">
                                    Work units:
                                </div>
                                <div className="info-section-row-item text-small">
                                    {selectedFeature.room?.room?.metrics?.workUnits}
                                </div>
                            </div>
                            <div className={showCopyEmail ? "mapped-to-revit-record-model" : 'display-none'}>
                                <div className={
                                    coppied ? "copied-to-clipboard" : 'display-none'
                                }>
                                    <span className="copied-to-clipboard-item text-small"> Copied to clipboard </span>
                                </div>
                                <div className={selected ? "info-section-row-copy coppied" : "info-section-row-copy"}
                                    onClick={_onCopyRoom}
                                >
                                    <div
                                        className={selected ? "info-section-id-title coppied text-small" : "info-section-id-title text-small"}>
                                        Properties
                                    </div>
                                    <div
                                        className={selected ? "info-section-id coppied text-small" : "info-section-id text-small"}>
                                        {`{Name: ${selectedFeature.room?.room?.roomNumber || ''} ... }`}
                                    </div>
                                    <input className="floor-plan-btn info-section-btn text-small" type="button"
                                        value="Copy JSON"
                                    />
                                </div>
                            </div>
                            {showCopyEmail && getMappedToRevitRecordModel()}
                        </>
                    )
            }

        </div>
    )
})

export default InfoSection