import React from "react";
import { useAuth0 } from "@auth0/auth0-react"

import {
    Redirect,
    Route,
} from "react-router-dom";

export default function PrivateRoute({ component: Component, ...rest }: any) {
    const { isAuthenticated, isLoading, error } = useAuth0();

    if (isLoading) {
        // TODO: Pretty loading UI
        return <div >'Loading...'</div>
    }

    return (
        <Route
            {...rest}
            render={(props: any) => !isAuthenticated || error
                ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                : <Component {...props} />
            }
        />
    )
}
