import { FLOOR_PLAN_ENVIRONMENT } from "../constants";
import { getFromLocalStorage, setToLocalStorage } from "../helpers/helpers";
import {
    Building,
    BuildingLastUpdate,
    DispatchType,
    Floor,
    MappedToRevit,
    SearchResult,
    SelectedFeature, STATE,
    VisualValidationBuildingState
} from "./types";

export const ACTION_TYPES = {
    SET_SEARCH_RESULT: "SET_SEARCH_RESULT",
    SET_RECENT_SEARCH_RESULT: "SET_RECENT_SEARCH_RESULT",
    SET_BUILDING_RESULT: "SET_BUILDING_RESULT",
    SET_FLOOR: "SET_FLOOR",
    SET_BUILDING_ID: 'SET_BUILDING_ID',
    SET_BUILDING_LAST_UPDATE: 'SET_BUILDING_LAST_UPDATE',
    SET_MAPPED_TO_REVIT: "SET_MAPPED_TO_REVIT",
    SET_SELECTED_FEATURE: "SET_SELECTED_FEATURE",
    SET_ERROR: "SET_ERROR",
    SET_LOADING: "SET_LOADING",
    SET_FLOOR_STATE: 'SET_FLOOR_STATE',
    SET_BUILDING_STATE: 'SET_BUILDING_STATE',
};

export function cleanFloorAndBuildingStatusData(buildingId: string, token: string) {
    return async () => {
        Promise.all([
            setBuildingState(buildingId, VisualValidationBuildingState.READY, token),
            fetch(
                FLOOR_PLAN_ENVIRONMENT + '/latestHarvest/building/' + buildingId + '/floors/status',
                {
                    method: 'delete',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    })
                }
            ) as any
        ])
            .then(() => { })
            .catch(() => { })
    }
}

export function loadFloorState(buildingId: string, token: string) {
    return async (dispatch: DispatchType<[]>) => {
        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/latestHarvest/building/' + buildingId + '/floors/status',
            {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                })
            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                let payload = [];
                if (!data.error) {
                    payload = data.floors
                    dispatch(setError(null))
                } else {
                    dispatch(setError({
                        type: 'status-floor-error'
                    }))
                }
                const action = {
                    type: ACTION_TYPES.SET_FLOOR_STATE,
                    payload,
                }
                dispatch(action)
            }).catch((_error) => {
                dispatch(setError({
                    type: 'status-floor-error'
                }))
            })
    }
}

export function setFloorState(buildingId: string, floor: Floor, approved: boolean, token: string) {
    return async (dispatch: DispatchType<[]>) => {
        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/latestHarvest/building/' + buildingId + '/floors/status',
            {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    floors: [
                        {
                            id: floor.id,
                            prettyName: floor.label,
                            approved
                        }
                    ]
                })

            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                let payload = [];
                if (!data.error) {
                    payload = data.floors
                    dispatch(setError(null))
                } else {
                    dispatch(setError({
                        type: 'status-floor-error'
                    }))
                }
                const action = {
                    type: ACTION_TYPES.SET_FLOOR_STATE,
                    payload,
                }
                dispatch(action)
            }).catch((_error) => {
                dispatch(setError({
                    type: 'status-floor-error'
                }))
            })
    }
}

export function loadBuildingState(buildingId: string, token: string) {
    return async (dispatch: DispatchType<[]>) => {
        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/latestHarvest/building/' + buildingId + '/status',
            {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                })
            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                let payload = [];
                if (!data.error) {
                    payload = data
                    dispatch(setError(null))
                } else {
                    dispatch(setError({
                        type: 'status-building-error'
                    }))
                }

                const action = {
                    type: ACTION_TYPES.SET_BUILDING_STATE,
                    payload,
                }
                dispatch(action)
            }).catch((_error) => {
                dispatch(setError({
                    type: 'status-building-error'
                }))
            })
    }
}

export function setBuildingState(buildingId: string, state: VisualValidationBuildingState, token: string) {
    return async (dispatch: DispatchType<[]>) => {
        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/latestHarvest/building/' + buildingId + '/status',
            {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ "status": state })

            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                let payload = [];
                if (!data.error) {
                    payload = data
                    dispatch(setError(null))
                } else {
                    dispatch(setError({
                        type: 'status-building-error'
                    }))
                }

                const action = {
                    type: ACTION_TYPES.SET_BUILDING_STATE,
                    payload,
                }
                dispatch(action)
            }).catch((_error) => {
                dispatch(setError({
                    type: 'status-building-error'
                }))
            })
    }
}

export function fetchSearchResult(query: string, token: string) {
    return async (dispatch: DispatchType<[]>) => {
        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/buildingsearch?query=' + query,
            {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                let payload = [];
                if (!data.error) {
                    payload = data
                    dispatch(setError(null))
                } else {
                    dispatch(setError({}))
                }
                const action = {
                    type: ACTION_TYPES.SET_SEARCH_RESULT,
                    payload,
                }
                dispatch(action)
            }).catch((_error) => {
                dispatch(setError({}))
            })
    }
}
export function cleanSearchResult() {
    return {
        type: ACTION_TYPES.SET_SEARCH_RESULT,
        payload: null,
    }
}

export function setRecentSearchResult(result: SearchResult) {
    return (dispatch: DispatchType<SearchResult[]>, getState: any) => {
        const recentSearchResults = getState().recentSearchResults;

        const newRecentSearchResults = recentSearchResults?.filter((item: any) => item?.algoliaId === result?.algoliaId).length
            ? recentSearchResults
            : [result, ...recentSearchResults.slice(0, 9)]

        const action = {
            type: ACTION_TYPES.SET_RECENT_SEARCH_RESULT,
            payload: newRecentSearchResults,
        };
        dispatch(action)
        setToLocalStorage('recentSearchResults', newRecentSearchResults);
    }
}


export function setSelectedFeature(item: SelectedFeature) {
    return (dispatch: DispatchType<SelectedFeature>) => {
        const action = {
            type: ACTION_TYPES.SET_SELECTED_FEATURE,
            payload: item,
        };
        dispatch(action)
    }
}

export function setFloor(item: Floor) {
    return (dispatch: DispatchType<Floor>) => {

        const action = {
            type: ACTION_TYPES.SET_FLOOR,
            payload: item,
        };
        dispatch(action);
        if (item) {
            const mappedToRevit = getFromLocalStorage('mappedToRevit' + item.id, {
                rooms: [], chairs: [],
            });
            const actionMappedToRevit = {
                type: ACTION_TYPES.SET_MAPPED_TO_REVIT,
                payload: mappedToRevit,
            };
            dispatch(actionMappedToRevit);
            setToLocalStorage('mappedToRevit' + item.id, mappedToRevit);
        }
    }
}

export function cleanFloor() {
    return {
        type: ACTION_TYPES.SET_FLOOR,
        payload: null,
    }
}

export function setMappedToRevit(newMappedToRevit: MappedToRevit) {
    return (dispatch: DispatchType<MappedToRevit>, getState: any) => {
        const floor = getState().floor;

        const action = {
            type: ACTION_TYPES.SET_MAPPED_TO_REVIT,
            payload: newMappedToRevit,
        };
        dispatch(action);
        setToLocalStorage('mappedToRevit' + floor.id, newMappedToRevit);
    }
}

export function setMappedToRevitFromLocalStorage() {
    return (dispatch: DispatchType<MappedToRevit>, getState: () => STATE) => {
        const floor = getState().floor;
        const localMappedToRevit = getFromLocalStorage('mappedToRevit' + floor?.id, []);

        const action = {
            type: ACTION_TYPES.SET_MAPPED_TO_REVIT,
            payload: localMappedToRevit,
        };
        dispatch(action);
    }
}

export function fetchBuildingResultByAlgoliaId(algoliaId: string, token: string) {
    return (dispatch: DispatchType<boolean | Building>) => {
        dispatch(setLoading(true));

        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/building/algolia/' + algoliaId,
            {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                dispatch(setLoading(false));
                const action = {
                    type: ACTION_TYPES.SET_BUILDING_RESULT,
                    payload: data,
                }
                dispatch(action);
            }).catch((error) => {
                dispatch(setLoading(false));
                dispatch(setError(error.error));
            })
    }
}

export function fetchBuildingResult(id: string, token: string) {
    return (dispatch: DispatchType<boolean | Building>) => {
        dispatch(setLoading(true));

        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/building/' + id,
            {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                dispatch(setLoading(false));
                const action = {
                    type: ACTION_TYPES.SET_BUILDING_RESULT,
                    payload: data,
                }
                dispatch(action);
            }).catch((error) => {
            dispatch(setLoading(false));
            dispatch(setError(error.error));
        })
    }
}

export function setBuildingId(data: string) {
    return {
        type: ACTION_TYPES.SET_BUILDING_ID,
        payload: data,
    }
}

export function cleanBuildingResult() {
    return {
        type: ACTION_TYPES.SET_BUILDING_RESULT,
        payload: null,
    }
}

export function fetchBuildingLastUpdate(buildingId: string, token: string) {
    return (dispatch: DispatchType<boolean | BuildingLastUpdate>) => {
        fetch(
            FLOOR_PLAN_ENVIRONMENT + '/latestHarvest/building/' + buildingId + '/last-update ',
            {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token
                })
            }
        )
            .then(res => res.ok ? res.json() : res.json().then(json => Promise.reject(json)))
            .then((data) => {
                dispatch(setLoading(false));
                const action = {
                    type: ACTION_TYPES.SET_BUILDING_LAST_UPDATE,
                    payload: data,
                }
                dispatch(action);
            }).catch((error) => {
                dispatch(setError(error.error));
            }).finally(() => {

            })
    }
}

// ERROR AND 

export function setLoading(state: boolean) {
    return {
        type: ACTION_TYPES.SET_LOADING,
        payload: state,
    }
}

export function setError(data: any) {
    return {
        type: ACTION_TYPES.SET_ERROR,
        payload: data,
    }
}